import {
    FeedbackPage,
    FeedbackPageAnonymizeSetting,
    FeedbackPageStyle,
    getFeedbackPageLogoSrc
} from '../../api/feedbackPage';

export interface FeedbackTheme {
    style: FeedbackPageStyle
    primaryColor: string
    logoUrl: string | null
    showPlaceholders?: boolean
    askPromoterScore: boolean
    anonymize: FeedbackPageAnonymizeSetting
    prompt: string
}

export const DEFAULT_PROMPT = 'Let us know how your experience was today'

export const toFeedbackTheme = (page: FeedbackPage, prompt?: string, logoSrc?: string, showPlaceholders?: boolean): FeedbackTheme => {
    if (!logoSrc) {
        if (page.hasLogo) {
            logoSrc = getFeedbackPageLogoSrc(page.spaceId)
        }
    }

    return {
        style: page.style,
        primaryColor: page.primaryColor,
        logoUrl: logoSrc ?? null,
        showPlaceholders,
        askPromoterScore: page.askPromoterScore,
        anonymize: page.anonymize,
        prompt: prompt ?? DEFAULT_PROMPT
    }
}

export const BACKGROUND_GRADIENT: Record<FeedbackPageStyle, string> = {
    [FeedbackPageStyle.LIGHT]: 'linear-gradient(#FFFFFF 0%, #D3D3E0 100%)',
    [FeedbackPageStyle.DARK]: 'linear-gradient(#2B2F37 0%, #1D0C26 100%)'
}

export const FOREGROUND_COLOR: Record<FeedbackPageStyle, string> = {
    [FeedbackPageStyle.LIGHT]: '#181C24',
    [FeedbackPageStyle.DARK]: '#F8F8FF'
}

export const FOREGROUND_GRADIENT: Record<FeedbackPageStyle, string> = {
    [FeedbackPageStyle.LIGHT]: 'linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(90, 97, 114, 0.5) 100%), #181C24',
    [FeedbackPageStyle.DARK]: 'linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(90, 97, 114, 0.5) 100%), #F8F8FF'
}

export const BACKGROUND_COLOR: Record<FeedbackPageStyle, string> = {
    [FeedbackPageStyle.LIGHT]: '#F8F8FF',
    [FeedbackPageStyle.DARK]: '#181C24'
}

export const primaryButtonGradient = (color: string): string => {
    // Overlay the color with a transparent grey gradient, to create a shiny feel
    return `linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(90, 97, 114, 0.5) 100%), ${color}`
}

export const transparent = (color: string, opacity: number): string => {
    // Change a Hex color to RGBA with the given opacity
    const hex = color.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
