import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import colors from './colors';
import typography from './Typography';

const NavigationLink = styled(NavLink)`
  padding: 5px 10px;
  border: 1px solid transparent;
  text-decoration: none;
  ${typography.body};
  
  &.active {
    border: 1px solid ${colors.text};
    border-radius: 10px;
  }
`

export default NavigationLink;
