
import React, { FC } from 'react';
import { FeedbackTheme } from '../theme';
import styled from 'styled-components';

interface Props {
    haloSize: number; // 0 to 1
    feedbackTheme: FeedbackTheme;
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const ButtonMain = styled.button<{ color: string, hoverColor: string }>`
    position: relative;
    width: 150px;
    height: 150px;
    background: ${props => props.color};
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #F8F8FF;
    font-size: 20px;
    font-family: DM Sans;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1px;
    word-wrap: break-word;
       border: none;

    &:hover {
        background: ${props => props.hoverColor};
    }

    transition: background-color 0.2s ease-in-out;
`;

const ButtonHalo = styled.div<{ size: number, color: string }>`
    position: absolute;
    width: ${props => 150 + (50 * props.size)}px;
    height: ${props => 150 + (50 * props.size)}px;
    background-color: ${props => props.color};
    border-radius: 50%;
    z-index: -1;
    opacity: 0.5;
    left: -${props => 25 * props.size}px;
    top: -${props => 25 * props.size}px;
  
    // Apply transition to width, height, left and top
  
      transition: width 0.2s ease-in-out, height 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out;
`;

const ButtonWithHalo: FC<Props> = ({ haloSize, active, onClick, feedbackTheme, children }) => {
    const color = active ? feedbackTheme.colors.recording : feedbackTheme.colors.primary;
    const hoverColor = active ? feedbackTheme.colors.recordingHover : feedbackTheme.colors.primaryHover;

    return <ButtonMain hoverColor={hoverColor} color={color} onClick={onClick}>
        <ButtonHalo size={haloSize} color={color} />
        {children}
    </ButtonMain>
}

export default ButtonWithHalo;
