import React, { FC } from 'react';
import styled from 'styled-components';
import colors from './colors';

interface Props {
    value: boolean
    onChange: (value: boolean) => void
}

const CheckboxDiv = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const CheckboxSelected = styled.div`
    width: 16px;
    height: 16px;
    background: ${colors.brand};
    border-radius: 2px;
`;

const Checkbox: FC<Props> = ({ value, onChange }) => {
    return <CheckboxDiv onClick={() => onChange(!value)} role="checkbox" aria-checked={value}>
        { value && <CheckboxSelected /> }
    </CheckboxDiv>;
}

export default Checkbox;
