import React, { FC, useEffect, useState } from 'react';
import { Column, Row } from '../../../atoms/Layout';
import PhonePreview from '../../../atoms/PhonePreview';
import { Body, BodyBold, Hint, LinkButton } from '../../../atoms/Typography';
import { useAppContext } from '../../../context/AppContext';
import { DEFAULT_PROMPT } from '../../../atoms/Feedback/theme';
import Loading from '../../../atoms/Loading';
import { useNavigate } from 'react-router-dom';
import { Check, Pencil, XMark } from '../../../atoms/Icon';
import { Channel, updateChannel } from '../../../api/channels';
import truncate from '../../../utils/truncate';
import { TextInput } from '../../../atoms/Input';
import FeedbackWizard from '../../../feedbackPage/templates/FeedbackWizard';
import { toFeedbackTheme } from '../../../feedbackPage/theme';

interface ChannelPromptRowProps {
    channel: Channel;
    active: boolean;
    makeActive: (value: boolean) => void;
    onChange: (value: string) => void;
}

const ChannelPromptRow: FC<ChannelPromptRowProps> = ({ channel, active, makeActive, onChange }) => {
    const [prompt, setPrompt] = useState<string>(channel.prompt || DEFAULT_PROMPT);
    const [saving, setSaving] = useState<boolean>(false);

    const updatePrompt = (value: string) => {
        setPrompt(value);
        onChange(value);
    }

    const cancel = () => {
        updatePrompt(channel.prompt || DEFAULT_PROMPT);
        makeActive(false);
    }

    const save = async () => {
        setSaving(true);
        await updateChannel(channel.id, channel.name, channel.category, prompt);
        setSaving(false);
        makeActive(false);
    }

    const startEditing = () => {
        makeActive(true);
        onChange(prompt);
    }

    return <Row key={channel.id} gap="small" vcenter highlight={active} padding="small">
        <Column size="half">
            <Body>{channel.name}</Body>
        </Column>
        <Column size="full">
            { !active && <Row gap="small" vcenter>
                <Body>{truncate(prompt, 40)}</Body>
                <Pencil width="24" height="24" style={{ cursor: 'pointer'}} onClick={startEditing}/>
            </Row> }
            { active && <Row gap="small" vcenter>
                <TextInput value={prompt} onChange={updatePrompt} />
                { saving && <Loading size="small" /> }
                { !saving && <>
                    <Check width="24" height="24" onClick={save}/>
                    <XMark width="24" height="24" onClick={cancel}/>
                </> }
            </Row> }
        </Column>
    </Row>
}

const FeedbackPagePrompt: FC = () => {
    const feedbackPage = useAppContext().feedbackPage;
    const channels = useAppContext().channels;
    const navigate = useNavigate();

    const [previewKey, setPreviewKey] = useState<number>(0);
    const [activeChannel, setActiveChannel] = useState<string | null>(null);
    const [prompt, setPrompt] = useState<string>(DEFAULT_PROMPT);

    useEffect(() => {
        void feedbackPage.load()
        void channels.load()
    }, [])

    if (!feedbackPage.value || !channels.value) {
        return <Row center>
            <Loading size="large" />
        </Row>
    }

    const theme = toFeedbackTheme({ ...feedbackPage.value!, askPromoterScore: false }, prompt, undefined, true)

    return <Row>
        <Column size="full" padding="medium" gap="medium" style={{ maxWidth: '600px', minWidth: '300px'}}>
            <LinkButton onClick={() => navigate('/dashboard/settings/feedback')}>Back</LinkButton>
            <BodyBold>Your Feedback Prompts / Questions</BodyBold>
            <Hint>These are the prompts / questions that will be asked to your customers when they give feedback.  Each channel can have a different question, allowing you to get specific feedback for specific parts of your building.</Hint>
            <Column>
                <Row gap="small" vcenter padding="small">
                    <Column size="half">
                        <BodyBold>Questions</BodyBold>
                    </Column>
                    <Column size="full">
                        <BodyBold>Prompt / Question</BodyBold>
                    </Column>
                </Row>
                { channels.value.map(channel => <ChannelPromptRow
                    key={channel.id}
                    channel={channel}
                    active={activeChannel === channel.id}
                    makeActive={value => setActiveChannel(value ? channel.id : null)}
                    onChange={setPrompt} />) }
            </Column>
        </Column>
        <Column gap="medium">
            <PhonePreview>
                <FeedbackWizard theme={theme} key={previewKey} onSubmit={async (value) => console.log(value)} />
            </PhonePreview>
            <Row center>
                <LinkButton onClick={() => setPreviewKey(prev => prev + 1)}>Refresh Preview</LinkButton>
            </Row>
        </Column>
    </Row>
}

export default FeedbackPagePrompt;
