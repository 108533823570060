import { FeedbackTopic } from 'api/feedback';
import axios from 'axios';

export interface InsightTheme {
    content: string;
    topic?: FeedbackTopic;
    sentimentScore: number;
}

export interface Insights {
    themes: InsightTheme[];
    actionPlan: string; // Markdown
    feedbackCount: number;
    createdAt: string;
}

export const getInsights = async (channelId?: string, forceRecreate?: boolean): Promise<Insights> => {
    let path = channelId ? `/api/insights/${channelId}` : '/api/insights'
    if (forceRecreate) {
        path += '?forceRecreate=true'
    }
    return (await axios.get(path)).data
}
