import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import colors from './colors';

interface Props {
    hue: number;
    onChange: (hue: number) => void;
}

const RangeTrack = styled.div`
    position: relative;
    height: 12px;
    border-radius: 6px;
    background: linear-gradient(to right,
    #ff0000 0%,
    #ff7f00 8.33%,
    #ffff00 16.66%,
    #80ff00 25%,
    #00ff00 33.33%,
    #00ff7f 41.66%,
    #00ffff 50%,
    #007fff 58.33%,
    #0000ff 66.66%,
    #7f00ff 75%,
    #ff00ff 83.33%,
    #ff007f 91.66%,
    #ff0000 100%);
`

const RangeThumb = styled.div<{hue: number}>`
    position: absolute;
    top: -10px;
    left: calc(${props => (props.hue / 3.6).toFixed(2)}% - 16px);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${colors.background};
    border: 1px solid ${colors.borderSecondary};
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const RangeThumbInner = styled.div<{hue: number}>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => `hsl(${props.hue}, 100%, 50%)`};
    border: 1px solid ${colors.borderSecondary};
`;


const HueSlider: FC<Props> = ({ hue, onChange}) => {
    const trackRef = React.useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = React.useState(false);

    const handleMouseMove = (event: MouseEvent, overrideDragging?: boolean) => {
        if (!dragging && !overrideDragging) return;
        const rect = trackRef.current?.getBoundingClientRect();
        if (rect) {
            let newHue = ((event.clientX - rect.left) / rect.width) * 360;
            newHue = Math.max(0, Math.min(360, newHue)); // Ensure within range
            onChange(newHue);
        }
        event.preventDefault();
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDragging(true);
        handleMouseMove(event.nativeEvent, true); // Initial hue calculation
        event.preventDefault()
    };

    const handleMouseUp = (event: MouseEvent) => {
        setDragging(false);
        handleMouseMove(event);
    };

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return <RangeTrack ref={trackRef} onMouseDown={handleMouseDown}>
            <RangeThumb hue={hue} onMouseDown={handleMouseDown}>
                <RangeThumbInner hue={hue} />
            </RangeThumb>
        </RangeTrack>
}

export default HueSlider;
