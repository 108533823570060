import { useEffect, useRef, useState } from 'react';

type VolumeDataCallback = (volume: number) => void; // Value from 0 to 1

export interface Recorder {
    isRecording: boolean;
    duration: number;
    microphoneDenied: boolean;
    startRecording: () => void;
    stopRecording: () => Blob | null;
}

const useRecorder = (onVolumeData: VolumeDataCallback): Recorder => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordingStartTime, setRecordingStartTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [microphoneDenied, setMicrophoneDenied] = useState(false);

    const audioContext = useRef<AudioContext>();
    const analyser = useRef<AnalyserNode>();
    const dataArray = useRef<Uint8Array>();
    const mediaRecorder = useRef<MediaRecorder>();
    const source = useRef<MediaStreamAudioSourceNode>();
    const chunks = useRef<Blob[]>([]);

    useEffect(() => {
        audioContext.current = new window.AudioContext();
        analyser.current = audioContext.current.createAnalyser();
        dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);
    }, []);

    // Register volume data callback
    useEffect(() => {
        if (!analyser.current) {
            return;
        }
        const interval = setInterval(() => {
            analyser.current!.getByteFrequencyData(dataArray.current!);
            const actualVolume = dataArray.current!.reduce((acc, curr) => acc + curr, 0) / dataArray.current!.length / 255;

            // Triple it and clamp, to get a more accurate representation of the volume
            const displayVolume = Math.min(1, Math.max(0, actualVolume * 3));
            onVolumeData(displayVolume);
        }, 100);
        return () => clearInterval(interval);
    }, [])

    // Update duration
    useEffect(() => {
        if (!isRecording) {
            return;
        }

        const interval = setInterval(() => {
            setDuration((Date.now() - recordingStartTime) / 1000);
        }, 100);

        return () => clearInterval(interval);
    }, [isRecording])

    const startRecording = async () => {
        try {
            if (!navigator.mediaDevices) {
                setMicrophoneDenied(true);
                return;
            }

            const stream = await navigator.mediaDevices.getUserMedia({audio: true})
            setMicrophoneDenied(false);
            source.current = audioContext.current!.createMediaStreamSource(stream);
            source.current.connect(analyser.current!);
            mediaRecorder.current = new MediaRecorder(stream);
            mediaRecorder.current.addEventListener('dataavailable', (e) => {
                chunks.current.push(e.data);
            });
            mediaRecorder.current.addEventListener('stop', () => {
                chunks.current = [];
            });
            mediaRecorder.current.start(100);
            setRecordingStartTime(Date.now());
            setIsRecording(true);
        } catch (e) {
            setMicrophoneDenied(true);
        }
    }

    const stopRecording = () => {
        if (!mediaRecorder.current) {
            return null;
        }
        mediaRecorder.current.stop();
        source.current!.disconnect();

        if (mediaRecorder.current.stream) {
            mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
        }

        setIsRecording(false);
        setDuration((Date.now() - recordingStartTime) / 1000);
        return new Blob(chunks.current, { type: 'audio/ogg; codecs=opus' });
    }

    return {
        isRecording,
        duration,
        microphoneDenied,
        startRecording,
        stopRecording,
    }
}

export default useRecorder;
