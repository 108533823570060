


export default {
    text: '#E3E2F1',
    border: '#363A48',
    borderSecondary: '#a5a5af',
    background: '#181C24',
    button: '#4C46DC',
    buttonText: '#fff',
    hint: '#A1A1A4',
    panel: '#242932',
    brand: '#4C46DC',
    brandLight: '#EDE9FE',
    secondary: '#625582',
    modalBackground: 'rgba(6, 0, 24, 0.71)',
    inputBackground: '#242932',
}
