import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from '../../atoms/Layout';
import Loading from '../../atoms/Loading';
import FeedbackWizard from '../templates/FeedbackWizard';
import { toFeedbackTheme } from '../theme';
import { submitFeedback } from '../../api/feedback';
import { getTilePublicInformation, TilePublicInformation } from '../../api/tiles';
import TileSetup from '../templates/TileSetup';

const TileFeedback: FC = () => {
    const { id } = useParams();
    const [tileInfo, setTileInfo] = useState<TilePublicInformation | null>(null);

    React.useEffect(() => {
        if (id) {
            getTilePublicInformation(id).then(setTileInfo)
                .catch(() => {
                    window.location.href = '/';
                })
        }
    }, [id])

    if (!tileInfo || !id) {
        return <Row size="full" center>
            <Loading size="large" />
        </Row>
    }

    const theme = toFeedbackTheme(tileInfo.feedbackPage, tileInfo.prompt);

    if (!tileInfo.channelId) {
        return <TileSetup theme={theme} tileId={id} tilePublicInfo={tileInfo} />
    }

    return <FeedbackWizard theme={theme} onSubmit={value => submitFeedback(tileInfo.channelId!, value)} />
}

export default TileFeedback;
