import styled from 'styled-components';
import { Sentiment } from 'model/feedback';
import colors from './colors';

const SentimentCard = styled.div<{ sentiment?: Sentiment }>`
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    border-radius: 20px;
    background: ${props => props.sentiment ? colors.brand : colors.panel};
    border: ${props => props.sentiment ? 'none' : `1px solid ${colors.border}`};
    padding: 35px;
    cursor: pointer;
  
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: translate(0px, -5px);
    }
  
    @media (max-width: 768px) {
        min-width: 300px;
        height: 300px;
        flex-grow: 1;
    }
`;

export default SentimentCard;
