import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from './colors';

interface Props {
    hue: number;
    saturation: number;
    luminosity: number;
    onChange: (saturation: number, luminosity: number) => void;
}

const SquareTrack = styled.div<{hue: number}>`
    position: relative;
    height: 200px;
    background: ${props => `hsl(${props.hue}, 100%, 50%)`};
    background: linear-gradient(0deg, #fff, transparent), linear-gradient(to right, #000, transparent), hsl(${props => props.hue}, 100%, 50%);
    border-radius: 8px;
    border: 1px solid ${colors.borderSecondary};
`;

const Marker = styled.div<{saturation: number, luminosity: number}>`
    position: absolute;
    top: calc(${props => (props.luminosity-(0.5*props.saturation)) / (1 - 0.5 * props.saturation) * 100}% - 16px);
    left: calc(${props => props.saturation * 100}% - 16px);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${colors.background};
    border: 1px solid ${colors.borderSecondary};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
`;

const MarkerInner = styled.div<{hue: number, saturation: number, luminosity: number}>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => `hsl(${props.hue}, ${props.saturation * 100}%, ${props.luminosity * 100}%)`};
    border: 1px solid ${colors.borderSecondary};
`;

const SaturationLuminositySquare: FC<Props> = ({ hue, saturation, luminosity, onChange }) => {
    const trackRef = useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState(false);

    const handleMouseMove = (event: MouseEvent, overrideDragging?: boolean) => {
        if ((!overrideDragging && !dragging) || !trackRef.current) return;
        const rect = trackRef.current.getBoundingClientRect();
        let newSaturation = (event.clientX - rect.left) / rect.width;
        let newLuminosity = (0.5*newSaturation) + (1 - 0.5 * newSaturation) * ((event.clientY - rect.top) / rect.height);
        newSaturation = Math.max(0, Math.min(1, newSaturation));
        newLuminosity = Math.max(0.5 * newSaturation, Math.min(1, newLuminosity));
        onChange(newSaturation, newLuminosity);
        event.preventDefault()
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDragging(true);
        handleMouseMove(event.nativeEvent, true);
        event.preventDefault()
    };

    const handleMouseUp = (event: MouseEvent) => {
        setDragging(false);
        event.preventDefault()
    };

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return (
        <SquareTrack ref={trackRef} hue={hue} onMouseDown={handleMouseDown}>
            <Marker saturation={saturation} luminosity={luminosity} onMouseDown={handleMouseDown}>
                <MarkerInner hue={hue} saturation={saturation} luminosity={luminosity} />
            </Marker>
        </SquareTrack>
    );
};

export default SaturationLuminositySquare;
