import React, { FC } from 'react';
import { InlineWidget } from 'react-calendly';

const Calendly: FC = () => {
    return <>
        <InlineWidget url="https://calendly.com/wmendelsohn0/30min?hide_landing_page_details=1&text_color=181c24&primary_color=181c24" styles={{ height: '800px'}}/>
    </>
}

export default Calendly;
