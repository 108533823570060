
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from './colors';
import typography from './Typography';

interface Props {
    waveform: number[];
    duration: number;
    progress: number; // 0-1
    setProgress?: (progress: number) => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    position: relative;
    max-width: 100%;
    height: 80px;
`

const Waveform = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    position: absolute;
    width: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
    height: 80px;
    cursor: pointer;
`;

const TimeTag = styled.div<{ progress: number }>`
    position: absolute;
    top: -8px;
    left: calc(${props => props.progress * 100}%);
    ${typography.label};
    color: ${colors.brand};
    text-align: center;
    transform: translateX(-50%);
  
    &:after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 1px;
        height: 8px;
        background-color: ${colors.brand};
    }
`

const WaveformBar = styled.div<{ height: number, active: boolean}>`
    height: ${props => props.height}px;
    width: 3px;
    border-radius: 3px;
    background-color: ${props => props.active ? colors.brand : colors.secondary };
`;

const AudioWaveformDisplay: FC<Props> = ({ waveform, duration, progress, setProgress}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);

    useEffect(() => {
        setContainerWidth(containerRef.current?.offsetWidth || 0);

        const handleResize = () => {
            setContainerWidth(containerRef.current?.offsetWidth || 0);
        };

        window.addEventListener('resize', handleResize);

        // Clean up after unmounting or before re-running the effect
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleWaveformClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!containerRef.current || !setProgress) return;

        const rect = containerRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const clickedProgress = x / rect.width;

        setProgress(clickedProgress);
    }


    const slices = Math.floor(containerWidth / 6);
    const maxAmplitude = Math.max(...waveform);

    return <Container ref={containerRef}>
        <TimeTag progress={0}>0s</TimeTag>
        <TimeTag progress={1}>{duration.toFixed(0)}s</TimeTag>
        <Waveform onClick={handleWaveformClick}>
            { new Array(slices).fill(0).map((_, i) => {
                const sliceIndex = Math.floor(i * waveform.length / slices);

                return <WaveformBar key={i} height={(waveform[sliceIndex]/maxAmplitude) * 76 + 4} active={i / slices < progress} />
            })}

        </Waveform>
    </Container>
}

export default AudioWaveformDisplay;
