import styled from 'styled-components'
import React, { type FC } from 'react'
import colors from './colors';

const ProgressBarDiv = styled.div`
  height: 0;
  position: relative;
  border: 5px solid ${colors.secondary};
  border-radius: 5px;
  width: 100%;
`

const ProgressBarFill = styled.div`
  height: 0;
  position: absolute;
  top: -5px;
  left: -5px;
  border: 5px solid ${colors.brand};
  border-radius: 5px;
  transition: width 0.2s ease-in-out;
`

interface Props {
  progress: number
  maxProgress?: number
}

const ProgressBar: FC<Props> = ({ progress, maxProgress = 100 }) => {
  const percentage = Math.min(100, Math.max(0, (progress / maxProgress) * 100))
  return <ProgressBarDiv role="progressbar" aria-valuemax={maxProgress} aria-valuenow={progress}>
        <ProgressBarFill style={{ width: `${percentage}%` }} />
    </ProgressBarDiv>
}

export default ProgressBar
