
import React, { FC } from 'react';
import styled from 'styled-components';
import colors from 'atoms/colors';
import typography from 'atoms/Typography';
import { SENTIMENT_COLORS } from '../model/feedback';

interface InputProps<T> {
    value: T;
    onChange: (value: T) => void;
    max?: number;
    min?: number;
    error?: boolean;
    placeholder?: string;
    half?: boolean;
}

const StyledInput = styled.input<{ error?: boolean, half?: boolean }>`
    border: 1px solid ${colors.border};
    border-radius: 4px;
    padding: 8px;
    background-color: transparent;
    outline: ${props => props.error ? `2px solid ${SENTIMENT_COLORS.NEGATIVE}` : 'none'};
    ${typography.body};
    ${props => props.half ? 'max-width: 50%;' : ''}
  
    &:hover, &:focus {
      outline: 2px solid ${colors.brand};
    }
`

const StyledTextArea = styled.textarea`
    border: 1px solid ${colors.border};
    border-radius: 4px;
    padding: 8px;
    background-color: transparent;
    outline: none;
    resize: vertical;
    ${typography.body};
`;

const InputInternal: FC<InputProps<string> & { type: string }> = ({ value, onChange, type, min, max, error , placeholder, half }) => {
    return <StyledInput type={type} onChange={(e) => onChange(e.target.value)} value={value} maxLength={max} min={min} max={max} error={error} placeholder={placeholder} half={half} />
}

export const TextInput: FC<InputProps<string>> = (props) => {
    return <InputInternal {...props} type={'text'}/>
}

export const PhoneInput: FC<InputProps<string>> = (props) => {
    return <InputInternal {...props} type={'tel'}/>
}

export const EmailInput: FC<InputProps<string>> = (props) => {
    return <InputInternal {...props} type={'email'}/>
}

export const PasswordInput: FC<InputProps<string>> = (props) => {
    return <InputInternal {...props} type={'password'}/>
}

export const NumberInput: FC<InputProps<number>> = (props) => {
    const onChange = (value: string) => {
        if (value === '') {
            props.onChange(0);
        } else {
            props.onChange(parseInt(value));
        }
    }

    return <InputInternal max={props.max} min={props.min} error={props.error} type="number" value={props.value.toString(10)} onChange={onChange} half={props.half} />
}

export const TextAreaInput: FC<InputProps<string>> = (props) => {
    return <StyledTextArea onChange={(e) => props.onChange(e.target.value)} value={props.value} maxLength={props.max} placeholder={props.placeholder} />
}
