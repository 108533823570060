import React, { FC } from 'react';
import { Row } from './Layout';
import styled from 'styled-components';
import colors from './colors';
import { NavLink } from 'react-router-dom';
import typography from './Typography';

interface Tab {
    label: string;
    to: string;
}

interface Props {
    tabs: Tab[];
}

const TabRow = styled(Row)`
    border-bottom: 1px solid ${colors.borderSecondary};
    padding: 0 16px;
    gap: 16px;
`;

const TabLink = styled(NavLink)`
    padding: 8px 16px;
    border-bottom: 3px solid transparent;
    ${typography.body};
    text-decoration: none;
    margin-bottom: -1px;
  
    &.active, &:hover {
        color: ${colors.brand};
        border-bottom: 3px solid ${colors.brand};
    }
  
    @media (max-width: 768px) {
        padding: 8px 8px;
    }
`;

const NavigationTabs: FC<Props> = ({ tabs }) => {
    return <TabRow>
        {tabs.map((tab, index) => <TabLink key={index} to={tab.to}>{tab.label}</TabLink>)}
    </TabRow>
}

export default NavigationTabs;
