import {
    FeedbackPage,
    FeedbackPageAnonymizeSetting,
    FeedbackPageStyle,
    getFeedbackPageLogoSrc
} from '../api/feedbackPage';

export interface FeedbackTheme {
    style: FeedbackPageStyle
    colors: FeedbackThemeColors
    logoUrl: string | null
    showPlaceholders?: boolean
    askPromoterScore: boolean
    anonymize: FeedbackPageAnonymizeSetting
    prompt: string
}

export const DEFAULT_PROMPT = 'Let us know how your experience was today'

export const toFeedbackTheme = (page: FeedbackPage, prompt?: string, logoSrc?: string, showPlaceholders?: boolean): FeedbackTheme => {
    if (!logoSrc) {
        if (page.hasLogo) {
            logoSrc = getFeedbackPageLogoSrc(page.spaceId)
        }
    }

    return {
        style: page.style,
        logoUrl: logoSrc ?? null,
        showPlaceholders,
        askPromoterScore: page.askPromoterScore,
        anonymize: page.anonymize,
        prompt: prompt ?? DEFAULT_PROMPT,
        colors: createColors(page.style, page.primaryColor)
    }
}

export interface FeedbackThemeColors {
    primary: string;
    primaryHover: string;
    background: string;
    foreground: string;
    hint: string;
    recording: string;
    recordingHover: string;
    link: string;
    inputOutline: string;
    inputBackground: string;
}

export interface ThemeProps {
    feedbackTheme: FeedbackTheme
}

const darkenHexColor = (hex: string, percent: number = 10): string => {
    // Ensure the hex code starts with '#'
    if (hex[0] !== "#") {
        throw new Error("Invalid HEX color.");
    }

    // Remove the hash at the start if it's there
    hex = hex.slice(1);

    // Parse r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    // Darken each color component
    r = Math.round(r * (1 - percent / 100));
    g = Math.round(g * (1 - percent / 100));
    b = Math.round(b * (1 - percent / 100));

    // Reassemble the color components back into a hex code
    let newColor = (1 << 24) + (r << 16) + (g << 8) + b;

    // Convert it back to hex and return
    return `#${newColor.toString(16).slice(1)}`;
}

const DARK_COLORS: Omit<FeedbackThemeColors, "primary" | "primaryHover" | "link"> = {
    background: '#181C24',
    foreground: '#F8F8FF',
    hint: '#868181',
    recording: '#F96F64',
    recordingHover: darkenHexColor('#F96F64', 20),
    inputOutline: '#595C62',
    inputBackground: '#242932'
}

const LIGHT_COLORS: Omit<FeedbackThemeColors, "primary" | "primaryHover" | "link"> = {
    background: '#EAEAF6',
    foreground: '#181C24',
    hint: '#868181',
    recording: '#F96F64',
    recordingHover: darkenHexColor('#F96F64', 20),
    inputOutline: '#595C62',
    inputBackground: '#F8F8FF'
}

const createColors = (style: FeedbackPageStyle, primary: string): FeedbackThemeColors => ({
    ...(style === FeedbackPageStyle.DARK ? DARK_COLORS : LIGHT_COLORS),
    primary,
    primaryHover: darkenHexColor(primary, 20),
    link: darkenHexColor(primary, -25)
})
