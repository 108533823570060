
import React, { FC, useEffect, useRef } from 'react';
import AudioWaveformDisplay from '../atoms/AudioWaveformDisplay';

interface Props {
    audio: Blob;
    playing?: boolean;
    onAudioEnded?: () => void;
}

interface AudioData {
    duration: number;
    waveform: number[];
    context: AudioContext;
    makeAudioSource: () => AudioBufferSourceNode;
}

const getAudioData = async (audio: Blob): Promise<AudioData> => {
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(await audio.arrayBuffer());
    const channelData = audioBuffer.getChannelData(0);
    const binSize = 1024; // Define bin size as needed
    let index = 0;
    const volumes: number[] = [];

    while (index < channelData.length) {
        let sum = 0;
        const max = Math.min(index + binSize, channelData.length);

        for (let i = index; i < max; i++) {
            sum += channelData[i] ** 2;
        }

        const rms = Math.sqrt(sum / binSize);
        volumes.push(rms);

        index += binSize;
    }

    return {
        duration: audioBuffer.duration,
        waveform: volumes,
        context: audioContext,
        makeAudioSource: () => {
            const source = audioContext.createBufferSource()
            source.buffer = audioBuffer
            source.connect(audioContext.destination)
            return source
        }
    }
}

const AudioPlayback: FC<Props> = ({ audio, playing, onAudioEnded }) => {
    const [audioData, setAudioData] = React.useState<AudioData | null>(null);
    const [progress, setProgress] = React.useState<number>(0); // [0-1
    const audioSource = useRef<AudioBufferSourceNode | null>(null);
    const [playbackNumber, setPlaybackNumber] = React.useState<number>(0);

    useEffect(() => {
        getAudioData(audio).then(setAudioData);

        return () => {
            if (audioSource.current) {
                audioSource.current.stop()
                audioSource.current = null
            }
        }
    }, [audio])

    useEffect(() => {
        setPlaybackNumber(playbackNumber + 1);
    }, [playing])

    useEffect(() => {
        if (audioData && playing) {
            audioSource.current = audioData.makeAudioSource()
            audioSource.current.start(0, progress * audioData.duration)
            void audioData.context.resume()
            let previousTime = audioData.context.currentTime


            const intervalId = setInterval(() => {
                const newProgress = progress + (audioData.context.currentTime-previousTime) / audioData.duration
                setProgress(newProgress > 1 ? 1 : newProgress);
                if (newProgress >= 1) {
                    onAudioEnded?.();
                }
            }, 50);

            return () => {
                clearInterval(intervalId);
            }
        } else if (audioSource.current) {
            audioSource.current.stop()
            audioSource.current = null
            if (progress >= 1) {
                setProgress(0);
            }
        }
    }, [playbackNumber])

    const onProgressChanged = (newProgress: number) => {
        setProgress(newProgress);
        if (playing) {
            setPlaybackNumber(playbackNumber + 1)
        }
    }

    return <div>
        {audioData && <AudioWaveformDisplay waveform={audioData.waveform} duration={audioData.duration} progress={progress} setProgress={onProgressChanged}/> }
    </div>
}

export default AudioPlayback;
