
import React, { FC } from 'react';
import { FeedbackTheme } from '../theme';
import Layout from '../atoms/Layout';
import Background from '../atoms/Background';
import SymantiqLogo from '../atoms/SymantiqLogo';
import { BodySmall, InlineLink, Label, Subtitle, Title } from '../atoms/typography';
import styled from 'styled-components';
import Select from '../atoms/Select';
import { setUpTile, TilePublicInformation } from '../../api/tiles';
import TextInput from '../atoms/TextInput';
import Button from '../atoms/Button';

interface Props {
    theme: FeedbackTheme
    tilePublicInfo: TilePublicInformation
    tileId: string
}

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
`;

const TileSetup: FC<Props> = ({ theme, tileId, tilePublicInfo }) => {
    const [channelId, setChannelId] = React.useState<string | undefined>(undefined);
    const [addNewChannel, setAddNewChannel] = React.useState(false);
    const [newChannelName, setNewChannelName] = React.useState<string>("");
    const [saving, setSaving] = React.useState(false);
    const [saved, setSaved] = React.useState(false);

    const canSubmit = addNewChannel ? newChannelName.length > 0 : !!channelId;

    const submit = async () => {
        setSaving(true);
        await setUpTile(tileId, addNewChannel ? { newChannelName } : { channelId: channelId! })
        setSaving(false);
        setSaved(true);
    }

    if (saved) {
        return <Background feedbackTheme={theme}>
            <Layout>
                <SymantiqLogo feedbackTheme={theme} />
                <Title center feedbackTheme={theme}>You've successfully connected this tile to a channel</Title>
                <Subtitle feedbackTheme={theme} center>You may now close this tab.</Subtitle>
            </Layout>
        </Background>
    }

    if (saving) {
        return <Background feedbackTheme={theme}>
            <Layout>
                <SymantiqLogo feedbackTheme={theme} />
                <Title center feedbackTheme={theme}>Saving...</Title>
            </Layout>
        </Background>
    }

    return <Background feedbackTheme={theme}>
        <Layout>
            <SymantiqLogo feedbackTheme={theme} />
            <Title center feedbackTheme={theme}>Let's set up this tile</Title>
            <InputGroup>
                <Label feedbackTheme={theme}>Tile Code:</Label>
                <Label feedbackTheme={theme}>{tileId}</Label>
            </InputGroup>
            {!addNewChannel && <InputGroup>
                <Label feedbackTheme={theme}>Channel:</Label>
                <Select feedbackTheme={theme} value={channelId} onChange={e => setChannelId(e.target.value)}>
                    <option value={""}>Pick Channel</option>
                    {tilePublicInfo.setUp?.availableChannels.map(channel => <option key={channel.id} value={channel.id}>{channel.name}</option>)}
                </Select>
            </InputGroup> }
            {addNewChannel && <InputGroup>
                <Label feedbackTheme={theme}>New Channel Name:</Label>
                <TextInput feedbackTheme={theme} value={newChannelName} onChange={e => setNewChannelName(e.target.value)}/>
            </InputGroup>}
            <InputGroup>
                <BodySmall feedbackTheme={theme} center>
                    <InlineLink feedbackTheme={theme} onClick={() => setAddNewChannel(!addNewChannel)}>
                        {addNewChannel ? "Pick a channel" : "Create a new channel"}
                    </InlineLink>
                </BodySmall>
            </InputGroup>
            <InputGroup>
                <Button feedbackTheme={theme} onClick={submit} disabled={!canSubmit}>Connect Tile</Button>
            </InputGroup>
        </Layout>
    </Background>
}

export default TileSetup;
