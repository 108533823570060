
import React, { FC } from 'react';
import { ThemeProps } from '../theme';


const SymantiqLogo: FC<ThemeProps> = ({ feedbackTheme }) => {
    return <svg width="91" height="28" viewBox="0 0 57 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Layer_2">
            <g id="Group">
                <path id="Vector" d="M7.65583 14.0571L5.39502 14.0555L0 0.177445L2.95772 0.158691L6.57114 10.0907L10.3319 0.174765L13.1818 0.179119L7.65583 14.0571Z" fill={feedbackTheme.colors.foreground} />
                <path id="Vector_2" d="M50.8649 0.0599469C47.7394 -0.0833844 46.3359 2.29163 46.3359 2.29163L46.3081 0.415262L43.9686 0.422629L43.9404 17.4834L46.3586 17.5346L46.3355 11.7398C46.3355 11.7398 47.5053 13.9694 50.7554 13.9694C56.9293 13.9694 56.9966 7.23087 56.9966 7.23087C56.9966 7.23087 57.3774 0.358665 50.8649 0.0599469ZM50.3716 11.6132C48.8877 11.6132 47.2963 10.9441 46.6292 8.82526C46.2917 7.75295 46.3034 6.59358 46.6125 5.51156C46.7826 4.91613 47.0458 4.26444 47.447 3.79527C48.1962 2.91954 49.1154 2.45472 50.3846 2.45472C51.6539 2.45472 54.2884 3.15698 54.5001 6.74461C54.712 10.3322 52.2135 11.6132 50.3716 11.6132Z" fill={feedbackTheme.colors.foreground} />
                <path id="Vector_3" d="M40.6611 2.13469C39.3286 0.821263 37.5822 0.164551 35.8357 0.164551C34.0893 0.164551 32.3429 0.821263 31.0103 2.13469L27.8105 5.28898L24.6107 2.13469C23.2782 0.821263 21.5317 0.164551 19.7853 0.164551C18.0389 0.164551 16.2924 0.821263 14.9599 2.13469C12.18 4.87506 12.18 9.318 14.9599 12.0584C16.2924 13.3718 18.0389 14.0285 19.7853 14.0285C21.5317 14.0285 23.2782 13.3718 24.6107 12.0584L27.8105 8.90408L31.0103 12.0584C32.3429 13.3718 34.0893 14.0285 35.8357 14.0285C37.5822 14.0285 39.3286 13.3718 40.6611 12.0584C43.4413 9.31833 43.4413 4.87506 40.6611 2.13469ZM22.8046 10.2788C21.9979 11.0738 20.9256 11.5118 19.7846 11.5118C18.644 11.5118 17.5714 11.0738 16.7646 10.2788C15.9023 9.42884 15.4274 8.2986 15.4274 7.0967C15.4274 5.89479 15.9023 4.76455 16.7646 3.91461C17.5714 3.11959 18.6437 2.68156 19.7846 2.68156C20.9252 2.68156 21.9979 3.11959 22.8046 3.91461L26.0326 7.0967L22.8046 10.2788ZM38.8554 10.2788C38.0487 11.0738 36.9763 11.5118 35.8354 11.5118C34.6948 11.5118 33.6221 11.0738 32.8154 10.2788L29.5874 7.0967L32.8154 3.91461C33.6221 3.11959 34.6944 2.68156 35.8354 2.68156C36.976 2.68156 38.0487 3.11959 38.8554 3.91461C39.7177 4.76455 40.1926 5.89479 40.1926 7.0967C40.1926 8.2986 39.7177 9.42884 38.8554 10.2788Z" fill={feedbackTheme.colors.foreground}/>
            </g>
        </g>
    </svg>
}

export default SymantiqLogo;
