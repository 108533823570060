
import React, { FC, useEffect } from 'react';
import { Column, Row } from '../../../atoms/Layout';
import Panel from '../../../atoms/Panel';
import { Subtitle } from '../../../atoms/Typography';
import TileList from '../../../templates/TileList';
import styled from 'styled-components';
import { useAppContext } from '../../../context/AppContext';
import OrderList from '../../../templates/OrderList';

const SettingsPanel = styled(Panel)`
  min-width: 400px;
  
  @media (max-width: 768px) {
    min-width: calc(100vw - 100px);
  }
`

const Tiles: FC = () => {
    const { orders } = useAppContext();

    useEffect(() => {
        void orders.load()
    }, [])

    return <Row padding="medium" gap="large">
        <Column size="full">
            <SettingsPanel>
                <Column gap="large">
                    <Row>
                        <Subtitle>Tiles</Subtitle>
                    </Row>
                    <TileList />
                </Column>
            </SettingsPanel>
        </Column>
        { orders.loaded && !!orders.value?.length && <Column size="full">
            <SettingsPanel>
                <Column gap="large">
                    <Row>
                        <Subtitle>Orders</Subtitle>
                    </Row>
                    <OrderList />
                </Column>
            </SettingsPanel>
        </Column>}
    </Row>
}

export default Tiles;
