
import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import Logo from 'atoms/Logo';
import { Body } from 'atoms/Typography';

const PrivacyPolicy: FC = () => {
    return <Column size="full" padding="large" gap="medium">
        <Row padding="large">
            <Logo />
        </Row>
        <Body>
            <h1 className="c17" id="h.64hsyu9ubf4"><span className="c20">Privacy Policy</span></h1><h2 className="c13"
                                                                                                       id="h.9f6rnvakadrr">
            <span className="c9">BACKGROUND:</span></h2><p className="c6"><span>Voop LLC</span><span
            className="c4">&nbsp;understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of all of our customers and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</span>
        </p><h2 className="c13" id="h.lkzvxtdc9mrw"><span className="c9">1. Information About Us</span></h2><p
            className="c6"><span>VOOP LLC</span><span className="c4">&nbsp;registered in the United Arab Emirates with license number 1186419</span>
        </p><p className="c6"><span className="c4">Main trading address: One Central 8th Floor, Trade Centre, Trade Centre 2, Dubai, United Arab Emirates</span>
        </p><p className="c6"><span className="c4">Data Protection Officer: Satbir Sandhu.</span></p><p className="c6">
            <span className="c4">Email address: satbir@voop.ai</span></p><p className="c6"><span className="c4">Postal address: One Central 8th Floor, Trade Centre, Trade Centre 2, Dubai, United Arab Emirates</span>
        </p><h2 className="c13" id="h.pbkxiga0kvxd"><span className="c9">2. What Does This Notice Cover?</span></h2><p
            className="c6"><span className="c4">This Privacy Information explains how we use your personal data: how it is collected, how it is held, and how it is processed. It also explains your rights under the law relating to your personal data.</span>
        </p><h2 className="c13" id="h.namunwrmfgnr"><span className="c9">3. What Is Personal Data?</span></h2><p
            className="c6"><span className="c4">Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, &ldquo;the Data Protection Legislation&rdquo;) as &lsquo;any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier&rsquo;.</span>
        </p><p className="c6"><span className="c4">Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</span>
        </p><p className="c6"><span className="c4">The personal data that we use is set out in Part 5, below.</span></p>
            <h2 className="c13" id="h.trhykzifwp90"><span className="c9">4. What Are My Rights?</span></h2><p
            className="c6"><span className="c4">Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:</span>
        </p><p className="c6"><span className="c4">a) The right to be informed about our collection and use of your personal data. This Privacy Notice should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 11.</span>
        </p><p className="c6"><span className="c4">b) The right to access the personal data we hold about you. Part 10 will tell you how to do this.</span>
        </p><p className="c6"><span className="c4">c) The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 11 to find out more.</span>
        </p><p className="c6"><span className="c4">d) The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us using the details in Part 11 to find out more.</span>
        </p><p className="c6"><span className="c4">e) The right to restrict (i.e. prevent) the processing of your personal data.</span>
        </p><p className="c6"><span className="c4">f) The right to object to us using your personal data for a particular purpose or purposes.</span>
        </p><p className="c6"><span className="c4">g) The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</span>
        </p><p className="c6"><span className="c4">h) The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</span>
        </p><p className="c6"><span className="c4">i) Rights relating to automated decision-making and profiling. we do not use your personal data in this way.</span>
        </p><p className="c6"><span className="c4">For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 11.</span>
        </p><p className="c6"><span className="c4">It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</span>
        </p><p className="c6"><span className="c4">Further information about your rights can also be obtained from the Information Commissioner&rsquo;s Office or your local Citizens Advice Bureau.</span>
        </p><p className="c6"><span className="c4">If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner&rsquo;s Office. We would welcome the opportunity to resolve your concerns ourselves, however, so please contact us first, using the details in Part 11.</span>
        </p><h2 className="c13" id="h.dd355jtij6gk"><span
            className="c9">5. What Personal Data Do You Collect and How?</span></h2><p className="c6"><span
            className="c4">We may collect and hold some or all of the personal and non-personal data set out in the table below, using the methods also set out in the table. We do not collect any &lsquo;special category&rsquo; or &lsquo;sensitive&rsquo; personal data.</span>
        </p><a id="t.3f551ed83bf045c94cd8c2601e1f1d25e0a40d91"></a><a id="t.0"></a>
            <table className="c14">
                <tr className="c7">
                    <td className="c5"><p className="c0"><span
                        className="c2">Data Collected</span></p></td>
                    <td className="c12"><p className="c0"><span className="c2">How We Collect the Data</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c5"><p className="c0"><span className="c2">Identity Information including Full name.</span>
                    </p></td>
                    <td className="c12"><p className="c0"><span className="c2">Voop server. Gmail.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c5"><p className="c0"><span className="c2">Contact information including business name, job title, business address, email and phone number.</span>
                    </p></td>
                    <td className="c12"><p className="c0"><span className="c2">Voop server. Gmail.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c5"><p className="c0"><span className="c2">Business information including business name, job title, business address, email and phone number.</span>
                    </p></td>
                    <td className="c12"><p className="c0"><span className="c2">Voop server. Gmail.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c5"><p className="c0"><span className="c2">Profile information including purchase history</span>
                    </p></td>
                    <td className="c12"><p className="c0"><span className="c2">Voop Dashboard Usage</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c5"><p className="c0"><span className="c2">Data from third parties including information, profile information. Full name, business name, job title, business address, email, phone number</span>
                    </p></td>
                    <td className="c12"><p className="c0"><span className="c2">Voop server. Gmail.</span>
                    </p></td>
                </tr>
            </table>
            <h2 className="c13" id="h.i5gqfncrzgwr"><span className="c9">6. How Do You Use My Personal Data?</span></h2>
            <p className="c6"><span className="c4">Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The following table describes how we may use your personal data, and our lawful bases for doing so:</span>
            </p><a id="t.cada79636c38ff11af50bc82ba640e081a974cb8"></a><a id="t.1"></a>
            <table className="c14">
                <tr className="c22">
                    <td className="c15"><p className="c0"><span className="c2">What We Do</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">What We Do</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span
                        className="c2">Our Lawful Basis</span></p></td>
                </tr>
                <tr className="c18">
                    <td className="c15"><p className="c0"><span className="c2">Administering our business.</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">Full name.</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span className="c2">To record voice, supply feedback on customers experience. To provide analytical information for customers dashboard.</span>
                    </p></td>
                </tr>
                <tr className="c1">
                    <td className="c15"><p className="c0"><span className="c2">Supplying our products and services to you.</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">Name. Business address, email &amp; contact number.</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span className="c2">To send out welcome pack to customers address</span>
                    </p></td>
                </tr>
                <tr className="c1">
                    <td className="c15"><p className="c0"><span className="c2">Managing payments for our products and services.</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">bank account number, sort code, billing address and name.</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span className="c2">Stripe</span></p>
                    </td>
                </tr>
                <tr className="c18">
                    <td className="c15"><p className="c0"><span className="c2">Personalizing and tailoring our products and services for you.</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">Full name.</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span className="c2">To record voice, supply feedback on customers experience. To provide analytical information for customers dashboard.</span>
                    </p></td>
                </tr>
                <tr className="c18">
                    <td className="c15"><p className="c0"><span className="c2">Communicating with you.</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">business name, job title, business address, email and phone number.</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span className="c2">Gmail. To inform you in regards to onboarding, abandoned sign ups, upcoming payments due/to be taken.</span>
                    </p></td>
                </tr>
                <tr className="c1">
                    <td className="c15"><p className="c0"><span className="c2">Supplying you with information by email or post that you have opted-in-to (you may opt-out at any time by emailing support@voop.ai)</span>
                    </p></td>
                    <td className="c8"><p className="c0"><span className="c2">email and phone number.</span>
                    </p></td>
                    <td className="c10"><p className="c0"><span className="c2">Upcoming new offers &amp; discounts. Announcements.</span>
                    </p></td>
                </tr>
            </table>
            <p className="c6"><span className="c4">With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by email and telephone and text message or post with information, news, and offers on our products and services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out.</span>
            </p><p className="c6"><span className="c4">We will only use your personal data for the purpose(s) for which it was originally collected unless we reasonably believe that another purpose is compatible with that or those original purpose(s) and need to use your personal data for that purpose. If we do use your personal data in this way and you wish us to explain how the new purpose is compatible with the original, please contact us using the details in Part 11.</span>
        </p><p className="c6"><span className="c4">If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the purpose(s) for which it was originally collected,[we will inform you and explain the legal basis which allows us to do so.</span>
        </p><p className="c6"><span className="c4">In some circumstances, where permitted or required by law, we may process your personal data without your knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your legal rights.</span>
        </p><h2 className="c13" id="h.robhto6cyhny"><span
            className="c9">7. How Long Will You Keep My Personal Data?</span></h2><p className="c6"><span
            className="c4">We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):</span>
        </p><a id="t.a6ceb71fddc0242a189f4e72f1caeb991d4d0fd2"></a><a id="t.2"></a>
            <table className="c14">
                <tr className="c22">
                    <td className="c16"><p className="c0"><span
                        className="c2">Type of Data</span></p></td>
                    <td className="c19"><p className="c0"><span className="c2">How Long We Keep It</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c16"><p className="c0"><span className="c2">Identity Information including Full name</span>
                    </p></td>
                    <td className="c19"><p className="c0"><span className="c2">As long as a customer has an active subscription with Voop LLC or 3 years after a customer has canceled all subscriptions.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c16"><p className="c0"><span className="c2">Contact information including business name, job title, business address, email and phone number.</span>
                    </p></td>
                    <td className="c19"><p className="c0"><span className="c2">As long as a customer has an active subscription with Voop LLC or 3 years after a customer has canceled all subscriptions.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c16"><p className="c0"><span className="c2">Business information including business name, job title, business address, email and phone number.</span>
                    </p></td>
                    <td className="c19"><p className="c0"><span className="c2">As long as a customer has an active subscription with Voop LLC or 3 years after a customer has canceled all subscriptions.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c16"><p className="c0"><span className="c2">Payment information including details, bank account number, sort code, billing address and name.].</span>
                    </p></td>
                    <td className="c19"><p className="c0"><span className="c2">As long as a customer has an active subscription with Voop LLC or 3 years after a customer has canceled all subscriptions.</span>
                    </p></td>
                </tr>
                <tr className="c7">
                    <td className="c16"><p className="c0"><span className="c2">Profile information including, purchase history.</span>
                    </p></td>
                    <td className="c19"><p className="c0"><span className="c2">As long as a customer has an active subscription with Voop LLC or 3 years after a customer has canceled all subscriptions.</span>
                    </p></td>
                </tr>
            </table>
            <h2 className="c13" id="h.3jpf3q527iin"><span className="c9">8. How and Where Do You Store or Transfer My Personal Data?</span>
            </h2><p className="c6"><span className="c4">We will store some of your personal data in the UK. This means that it will be fully protected under the Data Protection Legislation.</span>
        </p><p className="c6"><span className="c4">We will only store your personal data store some of your personal data within the European Economic Area (the &ldquo;EEA&rdquo;). The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein. This means that your personal data will be fully protected under the EU GDPR and/or to equivalent standards by law. Transfers of personal data to the EEA from the UK are permitted without additional safeguards.</span>
        </p><p className="c6"><span className="c4">We may store some or all of your personal data in countries outside of the UK. These are known as &ldquo;third countries&rdquo;. We will take additional steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation as follows:</span>
        </p><p className="c6"><span>We ensure that your personal data is protected under binding corporate rules. Binding corporate rules are a set of common rules which all our companies are required to follow when processing personal data. For further information, please refer to </span><span
            className="c23"><a className="c3"
                               href="https://www.google.com/url?q=https://ico.org.uk/&amp;sa=D&amp;source=editors&amp;ust=1698260912623283&amp;usg=AOvVaw2qLYiwcPjaGERtZeRWO9eB">the Information Commissioner&rsquo;s Office</a></span><span
            className="c4">.</span></p><h2 className="c13" id="h.devbncjei8jd"><span className="c9">9. How Can I Access My Personal Data?</span>
        </h2><p className="c6"><span className="c4">If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a &ldquo;subject access request&rdquo;.</span>
        </p><p className="c6"><span className="c4">All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 11.</span>
        </p><p className="c6"><span className="c4">There is not normally any charge for a subject access request. If your request is &lsquo;manifestly unfounded or excessive&rsquo; (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.</span>
        </p><p className="c6"><span className="c4">We will respond to your subject access request within 21 days and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.</span>
        </p><h2 className="c13" id="h.8al06tpstmya"><span className="c9">10. How Do I Contact You?</span></h2><p
            className="c6"><span className="c4">To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details [(for the attention of Satbir Sandhu:</span>
        </p><p className="c6"><span className="c4">Email address: satbir@voop.ai</span></p><p className="c6"><span
            className="c4">Postal address: One Central 8th Floor, Trade Centre, Trade Centre 2, Dubai, United Arab Emirates</span>
        </p><h2 className="c13" id="h.jizjl7i2wnuw"><span className="c9">Changes to this Privacy Notice</span></h2><p
            className="c6"><span className="c4">We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.</span>
        </p><p className="c6"><span className="c4">Any changes will be made available on our website. This Privacy Notice was last updated on 20th July 2023.</span>
        </p><p className="c24"><span className="c21">&copy; 2023 VOOP LLC</span></p><p className="c11"><span
            className="c4"></span></p>
        </Body>

    </Column>
}

export default PrivacyPolicy;
