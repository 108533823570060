import axios from 'axios';
export enum TileOrderStatus {
    PENDING = 'PENDING',
    SENT = 'SENT'
}

export interface ShippingAddress {
    name: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    postcode: string,
    state: string,
    country: string
}

export interface TileOrder {
    orderNumber: string,
    spaceId: string,
    createdAt: string,
    status: TileOrderStatus,
    quantity: number,
    totalPriceGBP: number,
    shippingAddress: ShippingAddress
}

export const previewTile = async (title: string, subtitle: string, logo?: File): Promise<Blob> => {
    const formData = new FormData()
    formData.append('title', title)
    formData.append('subtitle', subtitle)
    if (logo) {
        formData.append('logo', logo)
    }

    const res = await axios.post('/api/order/preview', formData, {
        responseType: 'blob'
    })

    return new Blob([res.data], { type: 'application/pdf' })
}

export const placeOrder = async (
        title: string,
        subtitle: string,
        logo: File | undefined,
        quantity: number,
        shippingAddress: ShippingAddress): Promise<TileOrder> => {
    const formData = new FormData()
    formData.append('title', title)
    formData.append('subtitle', subtitle)
    if (logo) {
        formData.append('logo', logo)
    }
    formData.append('quantity', quantity.toString())
    formData.append('name', shippingAddress.name)
    formData.append('phone', shippingAddress.phone)
    formData.append('addressLine1', shippingAddress.addressLine1)
    formData.append('addressLine2', shippingAddress.addressLine2)
    formData.append('city', shippingAddress.city)
    formData.append('postcode', shippingAddress.postcode)
    formData.append('state', shippingAddress.state)
    formData.append('country', shippingAddress.country)

    return (await axios.post('/api/order', formData)).data
}

export const getOrders = async (): Promise<TileOrder[]> => {
    return (await axios.get('/api/orders')).data
}
