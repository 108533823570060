
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
}

const TransitionContainer = styled.div<{ height: number }>`
    height: ${({ height }) => height}px;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

const TransitionWindow = styled.div<{ top: number }>`
    position: absolute;
    top: ${({ top }) => top}px;
    transition: top 0.5s ease-in-out;
    width: 100%;
`;

const TransitionItem = styled.div<{ top: number, opacity: number }>`
    position: absolute;
    top: ${({ top }) => top}px;
    width: 100%;
    opacity: ${({ opacity }) => opacity};
    transition: opacity 1s;
`;


const Transition: FC<Props> = ({ children }) => {
    const [currentChild, setCurrentChild] = React.useState<React.ReactNode | null>(null);
    const [previousChild, setPreviousChild] = React.useState<React.ReactNode | null>(null);
    const [previousY, setPreviousY] = React.useState<number>(0);
    const [currentY, setCurrentY] = React.useState<number>(0);
    const [currentChildHeight, setCurrentChildHeight] = React.useState<number>(0);
    const currentChildOpacity = useRef<number>(0)
    const previousChildOpacity = useRef<number>(1)
    const currentChildRef = React.useRef<HTMLDivElement>(null);
    const windowRef = React.useRef<HTMLDivElement>(null);
    const [refreshKey, setRefreshKey] = React.useState<number>(0);

    useEffect(() => {
        previousChildOpacity.current = 1;
        currentChildOpacity.current = 0;
        setPreviousChild(currentChild);
        setCurrentChild(children);
        setRefreshKey(refreshKey + 1);

        // Wait until the DOM updates after state change
        const timeout = setTimeout(() => {
            if (currentChildRef.current) {
                const newHeight = currentChildRef.current.offsetHeight;
                onHeightChange(newHeight);
            }
        }, 0);

        return () => clearTimeout(timeout); // Clean up on unmount or before running the effect
    }, [children]);

    // Re-check the height of the current child continuously
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentChildRef.current) {
                const newHeight = currentChildRef.current.offsetHeight;
                setCurrentChildHeight(newHeight)
                setPreviousY(currentY - newHeight);
            }
        }, 100);

        return () => clearInterval(interval);
    }, []);

    const onTransitionEnd = () => {
        setPreviousChild(null);
    }

    useEffect(() => {
        if (windowRef.current) {
            // Register transition end event
            windowRef.current.addEventListener('transitionend', onTransitionEnd);

            return () => windowRef.current?.removeEventListener('transitionend', onTransitionEnd);
        }
    }, [windowRef.current])

    const onHeightChange = (height: number) => {
        setCurrentChildHeight(height);
        setPreviousY(currentY);
        setCurrentY(currentY + height);
        previousChildOpacity.current = 0;
        currentChildOpacity.current = 1;
    }

    return <TransitionContainer height={currentChildHeight + 16}>
        <TransitionWindow top={-currentY} ref={windowRef}>
            { previousChild !== null && <TransitionItem top={previousY} opacity={previousChildOpacity.current} >
                {previousChild}
            </TransitionItem> }
            <TransitionItem top={currentY} ref={currentChildRef} opacity={currentChildOpacity.current} key={refreshKey} >
                {currentChild}
            </TransitionItem>
        </TransitionWindow>
    </TransitionContainer>

}

export default Transition;
