import React, { FC } from 'react';
import { FeedbackFilter, filterFeedback, Sentiment } from '../model/feedback';
import { Row } from '../atoms/Layout';
import { FeedbackResponse, FeedbackTopic } from '../api/feedback';
import { Channel } from '../api/channels';
import { Body, Subtitle } from '../atoms/Typography';
import SentimentIcon from '../atoms/SentimentIcon/index';
import styled from 'styled-components';
import colors from '../atoms/colors';
import { TextInput } from '../atoms/Input';
import Pill from '../atoms/Pill';

const FeedbackFiltersColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    gap: 16px;
`

const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.borderSecondary};
`;

interface Props {
    filter: FeedbackFilter;
    setFilter: (filter: FeedbackFilter) => void;
    feedback: FeedbackResponse[];
    channels: Channel[];
}

const FeedbackFilters: FC<Props> = ({ filter, setFilter, feedback, channels }) => {
    const filteredFeedback = filterFeedback(feedback, filter)
    const topics = feedback.flatMap(f => f.analysis.topics)
        .filter((topic, index, self) => self.findIndex(t => t.id === topic.id) === index)

    const toggleSentiment = (sentiment: Sentiment) => {
        if (filter.sentiments?.includes(sentiment)) {
            if (filter.sentiments.length === 1) {
                setFilter({
                    ...filter,
                    sentiments: undefined
                })
                return
            }
            setFilter({
                ...filter,
                sentiments: filter.sentiments.filter(s => s !== sentiment)
            })
        } else {
            setFilter({
                ...filter,
                sentiments: [...(filter.sentiments ?? []), sentiment]
            })
        }
    }

    const toggleChannel = (channel: Channel) => {
        if (filter.channelIds?.includes(channel.id)) {
            if (filter.channelIds.length === 1) {
                setFilter({
                    ...filter,
                    channelIds: undefined
                })
                return
            }
            setFilter({
                ...filter,
                channelIds: filter.channelIds.filter(l => l !== channel.id)
            })
        } else {
            setFilter({
                ...filter,
                channelIds: [...(filter.channelIds ?? []), channel.id]
            })
        }
    }

    const toggleTopic = (topic: FeedbackTopic) => {
        if (filter.topics?.includes(topic.id)) {
            if (filter.topics.length === 1) {
                setFilter({
                    ...filter,
                    topics: undefined
                })
                return
            }
            setFilter({
                ...filter,
                topics: filter.topics.filter(l => l !== topic.id)
            })
        } else {
            setFilter({
                ...filter,
                topics: [...(filter.topics ?? []), topic.id]
            })
        }
    }

    const channelCount = (channel: Channel) => filterFeedback(feedback, { ...filter, channelIds: [channel.id] }).length
    const topicCount = (topic: FeedbackTopic) => filterFeedback(feedback, { ...filter, topics: [topic.id] }).length

    return <FeedbackFiltersColumn>
        <Subtitle>Displaying {filteredFeedback.length} results</Subtitle>
        <Divider />
        <Body>By sentiment</Body>
        <Row gap="small">
            <SentimentIcon sentiment={Sentiment.POSITIVE} active={filter.sentiments?.includes(Sentiment.POSITIVE)} onClick={() => toggleSentiment(Sentiment.POSITIVE)} />
            <SentimentIcon sentiment={Sentiment.NEUTRAL} active={filter.sentiments?.includes(Sentiment.NEUTRAL)} onClick={() => toggleSentiment(Sentiment.NEUTRAL)} />
            <SentimentIcon sentiment={Sentiment.NEGATIVE} active={filter.sentiments?.includes(Sentiment.NEGATIVE)} onClick={() => toggleSentiment(Sentiment.NEGATIVE)} />
        </Row>
        <Divider />
        <Body>By keyword</Body>
        <TextInput value={filter.search ?? ''} onChange={value => setFilter({ ...filter, search: value || undefined })} placeholder="Search" />
        <Divider />
        <Body>By question</Body>
        <Row gap="small">
            { channels.map(channel => <Pill
                key={channel.id}
                active={filter.channelIds?.includes(channel.id)}
                onClick={() => toggleChannel(channel)}
            >
                {channel.name} ({channelCount(channel)})
            </Pill>) }
        </Row>
        <Divider />
        <Body>By topic</Body>
        <Row gap="small">
            { topics.map(topic => <Pill
                key={topic.id}
                active={filter.topics?.includes(topic.id)}
                onClick={() => toggleTopic(topic)}
            >
                {topic.name} ({topicCount(topic)})
            </Pill>) }
        </Row>


    </FeedbackFiltersColumn>
}

export default FeedbackFilters;
