import React, { FC } from 'react';
import Wizard from '../organisms/Wizard';
import { Column, DesktopRow, MobileRow, Row } from '../atoms/Layout';
import { Body, Hint, Label, Subtitle, Title } from '../atoms/Typography';
import TilePreview from '../organisms/TilePreview';
import { NumberInput, PhoneInput, TextInput } from '../atoms/Input';
import { VerticalSpace2 } from '../atoms/Space';
import FileUpload from '../molecules/FileUpload';
import Button from '../atoms/Button';
import { placeOrder, ShippingAddress } from '../api/orders';
import { useNavigate } from 'react-router-dom';

const DEFAULT_TITLE = 'Just scan & speak'
const DEFAULT_SUBTITLE = 'Let us know how your experience was today'

const PlaceTileOrderWizard: FC = () => {
    const navigate = useNavigate();
    const [step, setStep] = React.useState<number>(1);

    const [title, setTitle] = React.useState<string>('');
    const [subtitle, setSubtitle] = React.useState<string>('');
    const [logo, setLogo] = React.useState<File | undefined>(undefined);

    const logoFilesizeTooBig = logo && logo.size > 2 * 1024 * 1024;

    const [quantity, setQuantity] = React.useState<number>(3);

    const [name, setName] = React.useState<string>('');
    const [phone, setPhone] = React.useState<string>('');
    const [addressLine1, setAddressLine1] = React.useState<string>('');
    const [addressLine2, setAddressLine2] = React.useState<string>('');
    const [city, setCity] = React.useState<string>('');
    const [state, setState] = React.useState<string>('');
    const [postcode, setPostcode] = React.useState<string>('');
    const [country, setCountry] = React.useState<string>('');

    const [saving, setSaving] = React.useState<boolean>(false);

    const onSubmit = async () => {
        setSaving(true);

        const shippingAddress: ShippingAddress = {
            name,
            phone,
            addressLine1,
            addressLine2,
            city,
            state,
            postcode,
            country
        }

        await placeOrder(title || DEFAULT_TITLE, subtitle || DEFAULT_SUBTITLE, logo, quantity, shippingAddress);

        navigate('/dashboard/home');
    }

    return <Wizard currentStep={step} stepCount={3}>
        {step === 1 && <Row size="full" gap="large">
            <Column gap="small" size="full">
                <Row>
                    <Title>Let's design your tiles</Title>
                </Row>
                <Subtitle>Physical tiles encourage your guests to scan a QR code and leave you voice notes.</Subtitle>
                <VerticalSpace2 />
                <Label>Title</Label>
                <TextInput value={title} onChange={setTitle} max={64} placeholder={DEFAULT_TITLE} half />
                <VerticalSpace2 />
                <Label>Sub-title</Label>
                <TextInput value={subtitle} onChange={setSubtitle} max={64} placeholder={DEFAULT_SUBTITLE} half />
                <VerticalSpace2 />
                <Label>Company logo (Max 2MB)</Label>
                { logoFilesizeTooBig && <Label red>File size too large</Label> }
                <Row>
                    <FileUpload value={logo} onChange={setLogo} />
                </Row>
                <VerticalSpace2 />
                <DesktopRow>
                    <Button onClick={() => setStep(2)}>Continue</Button>
                </DesktopRow>
            </Column>
            <Column gap="large" >
                <Body>Tile Preview</Body>
                <TilePreview title={title || DEFAULT_TITLE} subtitle={subtitle || DEFAULT_SUBTITLE} logo={logoFilesizeTooBig ? undefined : logo} />
                <MobileRow>
                    <Button onClick={() => setStep(2)}>Continue</Button>
                </MobileRow>
            </Column>
        </Row>}
        {step === 2 && <Row size="full" gap="large">
            <Column gap="small" size="full">
                <Row><Title>Order physical tiles</Title></Row>
                <Row><Subtitle>We recommend ordering at least 2 tiles for each location.  The cost of tiles will be added to your monthly bill.</Subtitle></Row>
                <VerticalSpace2 />
                <Label>Number of tiles</Label>
                <Hint>3 is the minimum order</Hint>
                <NumberInput value={quantity} onChange={setQuantity} min={3} max={100} />
                <VerticalSpace2 />
                <Label>Tile cost</Label>
                <Body>{`£${quantity*5}.00`}</Body>
                <VerticalSpace2 />
                <Label>Delivery cost</Label>
                <Body>{`£3.50`}</Body>
                <VerticalSpace2 />
                <Label>Total cost</Label>
                <Body>{`£${quantity*5+3}.50`}</Body>
                <VerticalSpace2 />
                <Row>
                    <Button disabled={quantity < 3 || quantity > 100} onClick={() => setStep(3)}>Continue</Button>
                </Row>
            </Column>
        </Row>}
        {step === 3 && <Row size="full" gap="large">
            <Column gap="small" size="full">
                <Row><Title>Shipping Details</Title></Row>
                <VerticalSpace2 />
                <Label>Name</Label>
                <TextInput value={name} onChange={setName} max={64} half />
                <VerticalSpace2 />
                <Label>Phone</Label>
                <PhoneInput value={phone} onChange={setPhone} max={64} half />
                <VerticalSpace2 />
                <Label>Address Line 1</Label>
                <TextInput value={addressLine1} onChange={setAddressLine1} max={64} half />
                <VerticalSpace2 />
                <Label>Address Line 2</Label>
                <TextInput value={addressLine2} onChange={setAddressLine2} max={64} half />
                <VerticalSpace2 />
                <Label>City</Label>
                <TextInput value={city} onChange={setCity} max={64} half />
                <VerticalSpace2 />
                <Label>State / County</Label>
                <TextInput value={state} onChange={setState} max={64} half />
                <VerticalSpace2 />
                <Label>Post Code / ZIP code</Label>
                <TextInput value={postcode} onChange={setPostcode} max={64} half />
                <VerticalSpace2 />
                <Label>Country</Label>
                <TextInput value={country} onChange={setCountry} max={64} half />
                <VerticalSpace2 />
                <Row>
                    <Button loading={saving} disabled={!name || !phone || !addressLine1 || !city || !country} onClick={onSubmit}>Continue</Button>
                </Row>
            </Column>
        </Row>}
    </Wizard>
}

export default PlaceTileOrderWizard;
