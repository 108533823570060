import React, { FC } from 'react';
import { useAppContext } from '../context/AppContext';
import { Column, Row } from '../atoms/Layout';
import Loading from '../atoms/Loading';
import { Body } from '../atoms/Typography';
import moment from 'moment';
import styled from 'styled-components';

const OrderListColumnOuter = styled.div`
    white-space: nowrap;
    overflow-x: auto;
    max-width: calc(100vw - 140px);
`;

const OrderListColumnInner = styled(Column)`
    min-width: 700px;
`;

const OrderList: FC = () => {
    const { orders } = useAppContext();

    if (orders.loading) {
        return <Row center><Loading /></Row>
    }

    return <OrderListColumnOuter>
        <OrderListColumnInner gap="large">
            <Row gap="small" vcenter>
                <Column size="full">
                    <Body>Order number</Body>
                </Column>
                <Column size="full">
                    <Body>Date</Body>
                </Column>
                <Column size="full">
                    <Body>Tiles Ordered</Body>
                </Column>
                <Column size="full">
                    <Body>Status</Body>
                </Column>
            </Row>
            { orders.value?.map(order => <Row key={order.orderNumber} gap="small" vcenter>
                <Column size="full">
                    <Body>{order.orderNumber}</Body>
                </Column>
                <Column size="full">
                    <Body>{moment(order.createdAt).format("Do MMMM YYYY")}</Body>
                </Column>
                <Column size="full">
                    <Body>{order.quantity}</Body>
                </Column>
                <Column size="full">
                    <Body>{order.status}</Body>
                </Column>
            </Row>) }
        </OrderListColumnInner>
    </OrderListColumnOuter>
}

export default OrderList;
