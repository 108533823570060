import styled from 'styled-components';
import colors from 'atoms/colors';

interface FlexProps {
    size?: 'full' | 'half' | 'min',
    padding?: 'none' | 'small' | 'medium' | 'large',
    center?: boolean
    vcenter?: boolean
    hcenter?: boolean
    gap?: 'none' | 'small' | 'medium' | 'large',
    nowrap?: boolean
    onClick?: any;
    highlight?: boolean;
}

const FLEX_SIZE = {
    full: 1,
    half: 0.5,
    min: 0
}

const PADDING = {
    none: 0,
    small: '8px;',
    medium: '16px',
    large: '32px'
}

const GAPS = {
    none: 0,
    small: '8px',
    medium: '16px',
    large: '32px'
}

export const Column = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  flex: ${props => FLEX_SIZE[props.size || 'min']};
  flex-shrink: 0;
  align-items: ${props => (props.center || props.hcenter) ? 'center' : 'stretch'};
  justify-content: ${props => (props.vcenter) ? 'center' : 'flex-start'};
  padding: ${props => PADDING[props.padding || 'none']};
  gap: ${props => GAPS[props.gap || 'none']};
  cursor: ${props => props.onClick ? 'pointer' : 'auto'};
`;

export const FormColumn = styled(Column)`
  max-width: 600px;
`

export const Row = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  flex: ${props => FLEX_SIZE[props.size || 'min']};
  flex-shrink: 0;
  flex-wrap: ${props => props.nowrap ? 'nowrap' : 'wrap'};
  align-items: ${props => (props.center || props.vcenter) ? 'center' : 'stretch'};
  justify-content: ${props => (props.center || props.hcenter) ? 'center' : 'flex-start'};
  padding: ${props => PADDING[props.padding || 'none']};
  gap: ${props => GAPS[props.gap || 'none']};
  cursor: ${props => props.onClick ? 'pointer' : 'auto'};
  background-color: ${props => props.highlight ? colors.secondary : 'transparent'};
`;

export const MobileRow = styled(Row)`
    @media (min-width: 768px) {
        display: none;
    }
`;

export const DesktopRow = styled(Row)`
    @media (max-width: 768px) {
        display: none;
    }
`;

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100svh;
    overflow: auto;
`;

export const HorizontalDivider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${colors.borderSecondary};
`;

export const VerticalDivider = styled.div`
    height: 100%;
    border-right: 1px solid ${colors.borderSecondary};
`;
