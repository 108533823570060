
import React, { FC, useState } from 'react';
import { Column, Row } from '../atoms/Layout';
import { Label, LabelBold } from '../atoms/Typography';
import { HorizontalSpaceFlex1, VerticalSpace1 } from '../atoms/Space';
import { ChevronDown, ChevronUp } from '../atoms/Icon';

interface Props {
    title: string;
    children: React.ReactNode;
}

const Accordion: FC<Props> = ({ title, children }) => {
    const [open, setOpen] = useState(false);

    return <Row>
        <Column size="full">
            <Row vcenter gap="small" onClick={() => setOpen(!open)}>
                <LabelBold>{title}</LabelBold>
                <HorizontalSpaceFlex1 />
                { open ? <ChevronUp width="24" height="24" /> : <ChevronDown width="24" height="24" /> }
            </Row>
            {open && <VerticalSpace1 />}
            {open && <Row>
                <Label>
                    {children}
                </Label>
            </Row>}
        </Column>
    </Row>
}

export default Accordion;
