import React, { FC, ReactNode, useEffect } from 'react';
import Panel from '../atoms/Panel';
import ChecklistItem from '../molecules/ChecklistItem';
import { Body, Subtitle } from '../atoms/Typography';
import Button from '../atoms/Button';
import { Column, Row } from '../atoms/Layout';
import { useAppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface WelcomeChecklistItemProps {
    first: boolean
    last: boolean
    completed: boolean
    completeTitle: string
    incompleteTitle: string
    incompleteButtonText?: string
    onClick?: () => void
    children: ReactNode
}

const WelcomeChecklistItem: FC<WelcomeChecklistItemProps> = ({ first, last, completed, completeTitle, incompleteTitle, incompleteButtonText, onClick, children }) => {
    return <ChecklistItem checked={completed} first={first} last={last}>
        { completed && <Subtitle>{completeTitle}</Subtitle> }
        { !completed && <Row>
            <Column size="full" gap="small">
                <Subtitle>{incompleteTitle}</Subtitle>
                <Body>{children}</Body>
            </Column>
            { onClick && <Column>
                <Button onClick={onClick}>{incompleteButtonText}</Button>
            </Column> }
        </Row> }
    </ChecklistItem>
}

const Spacer = styled.div`
    width: 768px;
  
    @media (max-width: 768px) {
        width: calc(100vw - 100px);
    }
`

const WelcomeChecklist: FC = () => {
    const navigate = useNavigate()
    const { orders, channels, feedbackPage, space } = useAppContext()

    useEffect(() => {
        void orders.load();
        void feedbackPage.load();
        void channels.load();
        void space.load();
    }, []);

    const feedbackPageSetUp = !feedbackPage.value?.isDefault
    const channelsSetUp = !!channels.value?.length

    const allLoaded = orders.loaded && feedbackPage.loaded && channels.loaded && space.loaded
    const show = allLoaded && (!feedbackPageSetUp || !channelsSetUp)

    return <>
        <Panel hidden={!show}>
            <Column>
                <Spacer />
                <ChecklistItem checked={true} first={true} last={false}>
                    <Subtitle>You created your account</Subtitle>
                </ChecklistItem>
                <WelcomeChecklistItem completed={feedbackPageSetUp} first={false} last={false} completeTitle="You set up your feedback page" incompleteTitle="Set up your feedback page" incompleteButtonText="Set up" onClick={() => navigate('/dashboard/settings/feedback')}>
                    This is what your community will see when they give you feedback.
                </WelcomeChecklistItem>
                <WelcomeChecklistItem completed={channelsSetUp} first={false} last={true} completeTitle="You've created your first Voop question" incompleteTitle="Create your first Voop question" >
                    Create a question on the right to start collecting feedback from your community.
                </WelcomeChecklistItem>
            </Column>
        </Panel>
    </>
}

export default WelcomeChecklist;
