import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getChannelPublicInformation, ChannelPublicInformation } from '../../api/channels';
import { Row } from '../../atoms/Layout';
import Loading from '../../atoms/Loading';
import FeedbackWizard from '../templates/FeedbackWizard';
import { toFeedbackTheme } from '../theme';
import { submitFeedback } from '../../api/feedback';


const ChannelFeedback: FC = () => {
    const { id } = useParams();
    const [channelInfo, setChannelInfo] = useState<ChannelPublicInformation | null>(null);

    React.useEffect(() => {
        if (id) {
            getChannelPublicInformation(id).then(setChannelInfo)
                .catch(() => {
                    window.location.href = '/';
                })
        }
    }, [id])

    if (!channelInfo || !id) {
        return <Row size="full" center>
            <Loading size="large" />
        </Row>
    }

    return <FeedbackWizard theme={toFeedbackTheme(channelInfo.feedbackPage, channelInfo.prompt)} onSubmit={value => submitFeedback(id, value)} />
}

export default ChannelFeedback;
