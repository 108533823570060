import axios from 'axios';
import { FeedbackPage } from './feedbackPage';

export interface TileSetUpInformation {
    availableChannels: TileAvailableChannel[]
}

export interface TileAvailableChannel {
    id: string
    name: string
}

export interface TilePublicInformation {
    id: string
    feedbackPage: FeedbackPage
    channelId?: string
    setUp?: TileSetUpInformation
    prompt?: string
}

export interface Tile {
    id: string
    spaceId: string
    channelId?: string
}

export type SetUpTileCommand = { channelId: string } | { newChannelName: string }

export const getTilePublicInformation = async (tileId: string): Promise<TilePublicInformation | null> => {
    const res = await axios.get(`/api/tiles/public/${tileId}`)
    return res.data
}

export const setUpTile = async (tileId: string, command: SetUpTileCommand): Promise<void> => {
    const res = await axios.post(`/api/tiles/public/${tileId}/setup`, command)
    return res.data
}

export const getTiles = async (): Promise<Tile[]> => {
    const res = await axios.get(`/api/tiles`)
    return res.data
}

export const updateTile = async (tileId: string, channelId?: string): Promise<void> => {
    const res = await axios.put(`/api/tile/${tileId}`, { channelId })
    return res.data
}
