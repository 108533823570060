import axios from 'axios';

export enum FeedbackPageStyle {
    LIGHT = 'LIGHT',
    DARK = 'DARK'
}

export enum FeedbackPageAnonymizeSetting {
    ALWAYS = 'ALWAYS',
    NEVER = 'NEVER',
    OPTIONAL = 'OPTIONAL'
}

export interface FeedbackPage {
    spaceId: string
    style: FeedbackPageStyle
    primaryColor: string
    hasLogo: boolean
    isDefault: boolean
    anonymize: FeedbackPageAnonymizeSetting
    askPromoterScore: boolean
}

export const getFeedbackPage = async (): Promise<FeedbackPage> => {
    const res = await axios.get('/api/feedback-page')
    return res.data
}

export const updateFeedbackPage = async (
        style: FeedbackPageStyle,
        primaryColor: string,
        clearLogo: boolean,
        anonymize: FeedbackPageAnonymizeSetting,
        askPromoterScore: boolean,
        updateLogo?: File): Promise<FeedbackPage> => {
    const formData = new FormData()
    formData.append('style', style)
    formData.append('primaryColor', primaryColor)
    formData.append('clearLogo', clearLogo.toString())
    formData.append('askPromoterScore', askPromoterScore.toString())
    if (updateLogo) {
        formData.append('logo', updateLogo)
    }
    formData.append('anonymize', anonymize)

    const res = await axios.put('/api/feedback-page', formData)
    return res.data
}

export const getFeedbackPageLogoSrc = (spaceId: string): string => {
    return `/api/feedback-page/${spaceId}/logo`
}
