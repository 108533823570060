
import React, { FC } from 'react';
import { useAppContext } from '../context/AppContext';
import Avatar from '../atoms/Avatar';
import { ChevronDown } from '../atoms/Icon';
import styled from 'styled-components';
import colors from '../atoms/colors';
import { Body } from '../atoms/Typography';
import { useNavigate } from 'react-router-dom';
import { logout } from '../api/users';

const AccountMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: 72px;
    height: 32px;
    position: relative;
`;

const AccountMenuOverlay = styled.div`
    position: absolute;
    top: 40px;
    right: 0;
    left: -72px;
    width: 144px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: ${colors.panel};
    border-radius: 8px;
    padding: 8px;
`;

const AccountMenu: FC = () => {
    const user = useAppContext().user;
    const navigate = useNavigate()
    const [showMenu, setShowMenu] = React.useState(false);

    const onLogout = () => {
        void logout()
        navigate('/login')
    }

    return <AccountMenuContainer onClick={() => setShowMenu(!showMenu)}>
        <Avatar name={user.value?.name} />
        <ChevronDown height="24" width="24"/>
        {showMenu && <AccountMenuOverlay>
            <Body onClick={onLogout}>Log out</Body>
        </AccountMenuOverlay>}
    </AccountMenuContainer>
}

export default AccountMenu;
