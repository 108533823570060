
import React from 'react';
import styled from 'styled-components';
import GridGradient from './GridGradient.svg';

export const RainbowColorPane = styled.div`
  background: radial-gradient(circle,#2f2246 0,#131026 100%), url(${GridGradient});
  flex: 1;
  box-shadow: 0 4px 35px 0 rgba(206, 206, 206, 0.25);
  width: 100%;
  padding: 100px 200px;
  height: 100%;
  
@media (max-width: 768px) {
  padding: 100px 50px;
}
`;


