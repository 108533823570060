import React, { type FC } from 'react'
import styled from 'styled-components'
import { Hint } from '../atoms/Typography';
import ProgressBar from '../atoms/ProgressBar';
import { HorizontalSpaceFlex1 } from '../atoms/Space';
import { Row } from '../atoms/Layout';

interface Props {
  currentStep: number
  stepCount: number,
  children?: React.ReactNode
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    gap: 10px;
`

const StepProgressBar: FC<Props> = ({ currentStep, stepCount, children }) => {
  return <Container>
    <Row size='full'>
      {children}
      <HorizontalSpaceFlex1 />
      <Hint>Step {currentStep} of {stepCount}</Hint>
    </Row>
    <ProgressBar progress={currentStep} maxProgress={stepCount + 1} />
  </Container>
}

export default StepProgressBar
