import React, {FC, useEffect, useState} from "react";
import {Insights} from "../api/insights";
import {generateInsights} from "../api/promptPlayground";
import {Body} from "../atoms/Typography";

const DEFAULT_FEEDBACK_ITEM_TEMPLATE = `
    Sentiment score: {sentimentScore}
    Emotion: {emotion}
    Gender: {gender}
    Age: {age}
    Feedback: {textContent}
`;

const DEFAULT_THEME_TEMPLATE = `
    You are an assistant created to help video game developers, community managers and marketing professionals to improve their brand image.
    You will achieve this by reading and understanding feedback that is submitted by the community
    and providing clear, actionable and game-specific insights.  Each piece of feedback includes a sentiment
    score, which ranges from -1 (very negative) to 1 (very positive).
    
    Their game typically serves {communitySize} users per year.
    When asked about their game's description, they responded:
    {gameDescription}
    
    When asked about their products and services, they responded:
    {productsAndServices}
    
    When asked about their company mission, they responded:
    {companyMission}
    
    When asked about their short-term goals, they responded:
    {shortTermGoals}
    
    When asked about their long-term goals, they responded:
    {longTermGoals}
    
    Please read the following feedback and provide a theme that summarises the feedback.  This theme should be
    at most 4 sentences long, and should explain the primary theme of the feedback, its causes and effects.  The
    theme should be formatted as a statement of fact about the game, brand or community, so avoid opening with things like 
    "The primary theme of the feedback is" or "I think that".
    
    For example:
    
    "People are complaining that the matchmaking functionality for the 'battle royale' mode is too slow."
    
    FEEDBACK:
    
    {feedback}
    
    THEME:    
`;

const DEFAULT_ACTION_PLAN_TEMPLATE = `
    You are an assistant created to help video game developers, community managers and marketing professionals to improve their brand image.
    You will achieve this by providing an action plan with actionable insights based on some themes.  These
    themes were derived from feedback submitted by the community.  Each action item should be clear,
    game/brand-specific, and actionable. Action items should be kept brief, with a minimum of 2 sentences and a
    maximum of 4 sentences.
    
    Their game typically serves {communitySize} users per year.
    When asked about their game's description, they responded:
    {gameDescription}
    
    When asked about their products and services, they responded:
    {productsAndServices}
    
    When asked about their company mission, they responded:
    {companyMission}
    
    When asked about their short-term goals, they responded:
    {shortTermGoals}
    
    When asked about their long-term goals, they responded:
    {longTermGoals}
    
    Please read the following themes and provide an action plan with 2-3 numbered action items based on the themes.
    these action items should remedy any issues described by the themes, or improve upon any positive aspects.
    
    For example:
            
            1. **Improve visual clarity**: Consider using different shaders or lighting for enemies, ensuring that they stand out against the backdrop.  This will improve visual clarity, making it easier for players to identify enemies, especially players with visual impairments.
            2. **Improve prominence of user reporting functionality**: Provide a way for players to report other players for cheating within the game itself, instead of in an external website.  This will increase the number of reports that you receive, and help the community to understand that you take cheating seriously.
            
    THEMES:
    
    {themes}
    
    ACTION PLAN:
`;

const PromptPlayground: FC = () => {
    const [insights, setInsights] = useState<Insights | undefined>();

    useEffect(() => {
        const stream = generateInsights({
            context: {},
            feedbackResponses: [
                {
                    type: 'TEXT',
                    textContent: 'The monetization methods used in the game are way too expensive, it is quite unfair',
                    sentimentScore: 0,
                    topics: [],
                    language: 'en'
                }
            ],
            prompt: {
                feedbackItemTemplate: DEFAULT_FEEDBACK_ITEM_TEMPLATE,
                themeTemplate: DEFAULT_THEME_TEMPLATE,
                actionPlanTemplate: DEFAULT_ACTION_PLAN_TEMPLATE,
            }
        }, setInsights)

        return () => {
            stream.then(s => s.stopStream());
        }
    }, [])

    return <Body>{JSON.stringify(insights)}</Body>
}

export default PromptPlayground;