import styled from 'styled-components'

export const VerticalSpace1 = styled.div`
  height: 8px;
`

export const VerticalSpace2 = styled.div`
  height: 16px;
`

export const VerticalSpace3 = styled.div`
  height: 32px;
`

export const VerticalSpace4 = styled.div`
  height: 64px;
`

export const VerticalSpace5 = styled.div`
  height: 128px;
`

export const VerticalSpaceFlex1 = styled.div`
    flex: 1;
`

export const HorizontalSpace1 = styled.div`
    width: 8px;
`

export const HorizontalSpace2 = styled.div`
    width: 16px;
`

export const HorizontalSpace3 = styled.div`
    width: 32px;
`

export const HorizontalSpace4 = styled.div`
    width: 64px;
`

export const HorizontalSpace5 = styled.div`
    width: 128px;
`

export const HorizontalSpaceFlex1 = styled.div`
    flex: 1;
`
