
import React, { FC } from 'react';
import { FeedbackResponse } from 'api/feedback';
import { Column, Row, VerticalDivider } from 'atoms/Layout';
import styled from 'styled-components';
import { Sentiment, SENTIMENT_COLORS, toSentiment } from 'model/feedback';
import { Body } from 'atoms/Typography';

interface Props {
    feedback: FeedbackResponse[];
}

const SentimentBar = styled.div`
    width: 100%;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
`;

const SentimentBarSegment = styled.div<{ sentiment: Sentiment, widthPercent: number }>`
    background-color: ${props => SENTIMENT_COLORS[props.sentiment]};
    width: ${props => props.widthPercent}%;
    height: 100%;
`;

const OverallSentimentBreakdown: FC<Props> = ({ feedback }) => {
    const feedbackCountBySentiment = feedback.reduce<Record<Sentiment, number>>((acc, f) => {
        const sentiment = toSentiment(f.analysis.sentimentScore);
        acc[sentiment] = (acc[sentiment] ?? 0) + 1;
        return acc;
    }, {
        [Sentiment.NEGATIVE]: 0,
        [Sentiment.NEUTRAL]: 0,
        [Sentiment.POSITIVE]: 0,
    })
    const positivePercentage = feedbackCountBySentiment[Sentiment.POSITIVE] / feedback.length * 100;
    const neutralPercentage = feedbackCountBySentiment[Sentiment.NEUTRAL] / feedback.length * 100;
    const negativePercentage = feedbackCountBySentiment[Sentiment.NEGATIVE] / feedback.length * 100;

    if (feedback.length === 0) return <Column gap="medium">
        <Body>No Feedback Yet</Body>
    </Column>

    return <Column gap="medium">
        <Row>
            <SentimentBar>
                <SentimentBarSegment sentiment={Sentiment.NEGATIVE} widthPercent={negativePercentage} />
                <SentimentBarSegment sentiment={Sentiment.NEUTRAL} widthPercent={neutralPercentage} />
                <SentimentBarSegment sentiment={Sentiment.POSITIVE} widthPercent={positivePercentage} />
            </SentimentBar>
        </Row>
        <Row gap="medium">
            <Column size="full" center>
                <Body>
                    {negativePercentage.toFixed(0)}% negative
                </Body>
            </Column>
            <VerticalDivider />
            <Column size="full" center>
                <Body>
                    {neutralPercentage.toFixed(0)}% neutral
                </Body>
            </Column>
            <VerticalDivider />
            <Column size="full" center>
                <Body>
                    {positivePercentage.toFixed(0)}% positive
                </Body>
            </Column>
        </Row>
    </Column>
}

export default OverallSentimentBreakdown;
