
import React, { FC } from 'react';
import { SENTIMENT_COLORS, toSentiment } from 'model/feedback';
import colors from 'atoms/colors';
import styled from 'styled-components';
import typography from 'atoms/Typography';

interface Props {
    sentimentScore: number; // -1 to 1
}

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
}

const SentimentGaugeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    position: relative;
`;

const SentimentGaugeReadout = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    top: 0;
    left: 0;
    ${typography.label};
`;

const toScore = (averageSentiment: number): number => {
    const value = Math.max(0, Math.min(1, averageSentiment)); // Equivalent to clamp
    return value * value * (3 - 2 * value);
}

const SentimentGauge: FC<Props> = ({ sentimentScore }) => {
    const percentageFilled = toScore((sentimentScore + 1) * 0.5);
    const scoreOutOfTen = percentageFilled * 10;
    const sentiment = toSentiment(sentimentScore)
    const color = SENTIMENT_COLORS[sentiment];

    // Create a circular gauge with a filled arc
    // https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
    const path = describeArc(0, 0, 25, 0, percentageFilled * 360);
    const fullPath = describeArc(0, 0, 25, 0, 360);

    return <SentimentGaugeContainer>
        <svg viewBox="-30 -30 60 60" width="56" height="56">
            <path d={fullPath} stroke={colors.background} strokeWidth={6} strokeLinecap="round" fill="transparent"/>
            <path d={path} stroke={color} strokeWidth={6} strokeLinecap="round" fill="transparent"/>
        </svg>
        <SentimentGaugeReadout>{scoreOutOfTen.toFixed(1)}</SentimentGaugeReadout>
    </SentimentGaugeContainer>
}

export default SentimentGauge;
