
import React, { FC } from 'react';
import { BodySmall } from './typography';
import { FeedbackTheme } from '../theme';
import styled from 'styled-components';

interface Props {
    value: boolean;
    label: string;
    onChange: (value: boolean) => void;
    feedbackTheme: FeedbackTheme;
}

const Container = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const CheckboxOuter = styled.div<{ feedbackTheme: FeedbackTheme }>`
    width: 24px;
    height: 24px;
    border: 1px solid ${props => props.feedbackTheme.colors.foreground};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
`;

const CheckboxInner = styled.div<{ feedbackTheme: FeedbackTheme }>`
    width: 16px;
    height: 16px;
    background: ${props => props.feedbackTheme.colors.primary};
    border-radius: 2px;
`;

const Checkbox: FC<Props> = ({ value, label, onChange, feedbackTheme }) => {
    return <div onClick={() => onChange(!value)}>
        <Container>
            <div>
                <CheckboxOuter feedbackTheme={feedbackTheme}>
                    {value && <CheckboxInner feedbackTheme={feedbackTheme} />}
                </CheckboxOuter>
            </div>
            <BodySmall feedbackTheme={feedbackTheme}>{label} </BodySmall>
        </Container>
    </div>
}

export default Checkbox;
