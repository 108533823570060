
import React, { FC } from 'react';
import { StepProps } from './Step';
import { Subtitle } from '../atoms/typography';
import PromoterScoreSlider from '../atoms/PromoterScoreSlider';
import { Column, Row } from '../atoms/Layout';
import { HorizontalSpaceFlex1 } from '../../atoms/Space';
import Button from '../atoms/Button';

const PromoterScoreStep: FC<StepProps> = ({ feedbackTheme, onSubmit }) => {
    const [value, setValue] = React.useState<number | null>(null);

    return <Column>
        <Subtitle center feedbackTheme={feedbackTheme}>How likely are you to recommend us to a friend or colleague?</Subtitle>
        <PromoterScoreSlider value={value} onChange={setValue} theme={feedbackTheme} />
        <Row>
            <HorizontalSpaceFlex1 />
            <Button disabled={value === null} feedbackTheme={feedbackTheme} onClick={() => onSubmit({ promoterScore: value })}>Next</Button>
        </Row>

    </Column>
}

export default PromoterScoreStep;
