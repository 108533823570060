
import React, { type FC } from 'react'
import styled from 'styled-components'
import BrandName from '../atoms/BrandName/index';
import StepProgressBar from '../molecules/StepProgressBar';
import Panel from '../atoms/Panel';
import { Row } from '../atoms/Layout';

interface Props {
  currentStep: number
  stepCount: number
  children: React.ReactNode
}

const WizardColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 10px;
  width: 100%;
  gap: 16px;
  align-items: stretch;
`

const Wizard: FC<Props> = ({ currentStep, stepCount, children }) => {
  return <Row center size="full">
    <WizardColumn>
      <StepProgressBar currentStep={currentStep} stepCount={stepCount}>
        <BrandName />
      </StepProgressBar>
      <Panel>
        {children}
      </Panel>
    </WizardColumn>

  </Row>
}

export default Wizard
