
import React, { FC, useEffect, useState } from 'react';
import { previewTile } from '../api/orders';
import { Document, Page, pdfjs } from "react-pdf";
import styled from 'styled-components';
import Loading from '../atoms/Loading';


const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

interface Props {
    title: string;
    subtitle: string;
    logo: File | undefined;
}

const Container = styled.div`
    width: 300px;
    height: 424.5px;
    background-color: #160042;
    border-radius: 20px;
    position: relative;
`

const LoadingContainer = styled.div`
    width: 300px;
    height: 424.5px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

const CustomPage = styled(Page)`
    width: 300px;
    height: 424.5px;
    min-width: 0 !important;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 35px 0 rgba(206, 206, 206, 0.25);
    & canvas {
      transform: scale(0.5) translate(-50%, -50%);
    }
`

interface TilePDF {
    src: string;
    loaded: boolean;
}

const Placeholder = styled.div`
    width: 300px;
    height: 424.5px;
    background-color: #160042;
    border-radius: 20px;
`;

const TilePreview: FC<Props> = ({ title, subtitle, logo }) => {
    const [pdfs, setPDFs] = useState<TilePDF[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        let cancelled = false
        setLoading(true)

        previewTile(title, subtitle, logo).then(blob => {
            if (cancelled) return
            setPDFs([...pdfs, {
                src: URL.createObjectURL(blob),
                loaded: false
            }])
            setLoading(false)
        })

        return () => {
            cancelled = true
        }
    }, [title, subtitle, logo])

    const handleLoadComplete = (src: string) => {

        // Remove any pdfs before the one with this src
        const futurePdfs = pdfs.splice(pdfs.findIndex(pdf => pdf.src === src) + 1)
            .filter(pdf => !pdf.loaded)

        setPDFs([ { src, loaded: true }, ...futurePdfs])
    }

    return <Container>
        { pdfs.map(pdf =>
            <div key={pdf.src} style={{ display: pdf.loaded ? 'block' : 'none' }}>
                <Document file={pdf.src} loading={<Placeholder />}>
                    <CustomPage pageNumber={1} width={600} renderTextLayer={false} renderAnnotationLayer={false}
                                onRenderSuccess={() => handleLoadComplete(pdf.src)} loading={<Placeholder />} />
                </Document>
            </div>)
        }
        { ( loading || pdfs.some(pdf => !pdf.loaded)) && <LoadingContainer><Loading size="large" /></LoadingContainer>}
    </Container>

}

export default TilePreview;
