import React, { FC } from 'react';
import { Hint, Label, Title } from 'atoms/Typography';
import { EmailInput, PasswordInput, TextInput } from 'atoms/Input';
import { Column, Row } from 'atoms/Layout';
import Button from 'atoms/Button';
import { register } from '../api/users';
import { useNavigate } from 'react-router-dom';
import { SpaceType } from '../api/spaces';
import RadioGroup from '../molecules/RadioGroup';

const isEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null
}

const OnboardingForm: FC = () => {
    const navigate = useNavigate()

    const [stage, setStage] = React.useState<'space' | 'account'>('space')
    const [spaceName, setSpaceName] = React.useState<string>('')
    const [spaceType, setSpaceType] = React.useState<SpaceType>(SpaceType.GAMING)
    const [userName, setUserName] = React.useState<string>('')

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = React.useState<string>('')

    const [saving, setSaving] = React.useState<boolean>(false)
    const [emailInUse, setEmailInUse] = React.useState<boolean>(false)

    const onSubmit = async () => {
        setSaving(true)

        try {
            await register({
                email,
                password,
                spaceName,
                name: userName,
                spaceType
            })
            navigate('/welcome')
        } catch (e) {
            setEmailInUse(true)
            setSaving(false)
        }
    }

    return <Column gap="medium" style={{maxWidth: '500px'}} size='full'>
        <Title>Let's set up your Voop account</Title>
        { stage === 'space' && <>
            <Label>What industry do you work in?</Label>
            <RadioGroup value={spaceType} options={[{ value: SpaceType.GAMING, label: 'Games Industry'}, { value: SpaceType.MARKETING, label: 'Marketing'}]} onChange={value => setSpaceType(value as SpaceType)} />
            <Label>{spaceType == SpaceType.GAMING ? 'Team/Game Name' : 'Team/Client Name' }</Label>
            <TextInput value={spaceName} onChange={setSpaceName} max={64} half />
            <Label>Your Name</Label>
            <TextInput value={userName} onChange={setUserName} max={64} half />
            <Row>
                <Button disabled={!spaceName.trim() || !userName.trim()} onClick={() => setStage('account')}>Next</Button>
            </Row>
        </> }
        { stage === 'account' && <>
            <Label>Email Address</Label>
            { emailInUse && <Label red>Email address already in use</Label> }
            <EmailInput value={email} onChange={setEmail} error={!!(email &&!isEmail(email))} half />
            <Label>Password</Label>
            <Hint>Password must be at least 8 characters</Hint>
            <PasswordInput value={password} onChange={setPassword} error={!!password && password.length < 8} half />
            <Label>Confirm Password</Label>
            <PasswordInput value={passwordConfirm} onChange={setPasswordConfirm} error={!!(passwordConfirm && (passwordConfirm !== password))} half />
            <Row>
                <Button disabled={!isEmail(email) || password.length < 8 || passwordConfirm !== password} onClick={onSubmit} loading={saving}>Create account</Button>
            </Row>
        </> }
    </Column>
}

export default OnboardingForm;
