import React, { FC, useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import { Column, Row } from '../atoms/Layout';
import Loading from '../atoms/Loading';
import { Tile, updateTile } from '../api/tiles';
import { Body, Hint, LinkButton } from '../atoms/Typography';
import SelectInput, { SelectOption } from '../atoms/SelectInput';
import { Channel } from '../api/channels';
import { ArrowTopRightOnSquare } from '../atoms/Icon';
import Button from '../atoms/Button';
import { useNavigate } from 'react-router-dom';

const UNSET: SelectOption = {
    value: '',
    label: 'Unset'
}

interface TileRowProps {
    tile: Tile
    channels: Channel[]
    onChannelChange: (channelId: string | undefined) => Promise<void>
}

const TileRow: FC<TileRowProps> = ({ tile, channels, onChannelChange }) => {
    const [saving, setSaving] = useState(false)

    const options: SelectOption[] = [UNSET, ...channels.map(channel => ({
        value: channel.id,
        label: channel.name
    }))]

    const openTile = () => {
        window.open(`/feedback/tile/${tile.id}`, '_blank')
    }

    const onChange = async (channelId: string) => {
        setSaving(true)
        await onChannelChange(channelId || undefined)
        setSaving(false)
    }

    return <Row gap="small" vcenter>
        <Column size="full">
            { tile.channelId && <LinkButton onClick={openTile}>{tile.id} <ArrowTopRightOnSquare width="20" height="20"/></LinkButton> }
            { !tile.channelId && <Body>{tile.id}</Body> }
        </Column>
        { channels.length ?
            <Column size="full">
                {saving && <Loading />}
                {!saving && <SelectInput options={options} value={tile.channelId ?? ''} onChange={onChange} /> }
            </Column> : undefined
        }
        { !channels.length ? <Column size="full">
                <Body>Unset</Body>
            </Column> : undefined
        }
    </Row>
}

const TileList: FC = () => {
    const { tiles, channels } = useAppContext();
    const navigate = useNavigate()

    useEffect(() => {
        void tiles.load()
        void channels.load()
    }, [])

    const onChannelChange = async (tileId: string, channelId: string | undefined) => {
        await updateTile(tileId, channelId)
        tiles.setValue(tiles.value!.map(tile => tile.id === tileId ? { ...tile, channelId } : tile))
    }

    if (tiles.loading || channels.loading) {
        return <Row center><Loading /></Row>
    }

    if (!tiles.value?.length) {
        return <Row center>
            <Column gap="medium" size="full" center>
                <Hint>No tiles have been set up yet.  When you order tiles, they will appear here.</Hint>
                <Button onClick={() => navigate('/order')}>Order Tiles</Button>
            </Column>
        </Row>
    }

    const sortedTiles = tiles.value.sort((tile1, tile2) => {
        // Sort by ID (String)
        if (tile1.id < tile2.id) {
            return -1
        }
        if (tile1.id > tile2.id) {
            return 1
        }
        return 0
    })

    return <Column gap="large">
        { !channels.value?.length ?? <Row center>
            <Hint>You have no channels.  Go </Hint><LinkButton onClick={() => navigate('/dashboard/home')}>&nbsp;Here&nbsp;</LinkButton><Hint> to set some up!</Hint>
        </Row> }
        <Row gap="small" vcenter>
            <Column size="full">
                <Body>Tile ID</Body>
            </Column>
            <Column size="full">
                <Body>Channel</Body>
            </Column>
        </Row>
        { tiles && !!tiles.value && sortedTiles.map(tile => <TileRow tile={tile} channels={channels.value ?? []} key={tile.id} onChannelChange={id => onChannelChange(tile.id, id)}/>) }
    </Column>
}

export default TileList;
