import React, { FC } from 'react';
import { Sentiment } from '../../model/feedback';
import { ReactComponent as PositiveSentimentInactive } from './PositiveSentimentInactive.svg';
import { ReactComponent as PositiveSentimentActive } from './PositiveSentimentActive.svg';
import { ReactComponent as NegativeSentimentInactive } from './NegativeSentimentInactive.svg';
import { ReactComponent as NegativeSentimentActive } from './NegativeSentimentActive.svg';
import { ReactComponent as NeutralSentimentInactive } from './NeutralSentimentInactive.svg';
import { ReactComponent as NeutralSentimentActive } from './NeutralSentimentActive.svg';
import { ReactComponent as PlayInactive } from './PlayInactive.svg';

const COMPONENTS: Record<Sentiment, Record<'active' | 'inactive', typeof PositiveSentimentActive>> = {
    [Sentiment.POSITIVE]: {
        active: PositiveSentimentActive,
        inactive: PositiveSentimentInactive
    },
    [Sentiment.NEGATIVE]: {
        active: NegativeSentimentActive,
        inactive: NegativeSentimentInactive
    },
    [Sentiment.NEUTRAL]: {
        active: NeutralSentimentActive,
        inactive: NeutralSentimentInactive
    }
}

const SIZES = {
    medium: 58,
    large: 80
}


interface Props {
    sentiment: Sentiment,
    active?: boolean
    size?: 'medium' | 'large'
    onClick?: () => void
    play?: boolean
}

const SentimentIcon: FC<Props> = ({ sentiment, active, size, onClick, play}) => {
    const [mouseOver, setMouseOver] = React.useState(false);

    const displayActive = (onClick && mouseOver) ? !active : active;

    const Component = (play && !displayActive) ? PlayInactive: COMPONENTS[sentiment][displayActive ? 'active' : 'inactive']

    return <Component
        width={SIZES[size ?? 'medium']}
        height={SIZES[size ?? 'medium']}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        onClick={() => {
            setMouseOver(false);
            onClick?.();
        }}/>
}

export default SentimentIcon;
