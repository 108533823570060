import React, {FC, useMemo} from 'react';
import SentimentCard from '../atoms/SentimentCard';
import { HorizontalSpaceFlex1, VerticalSpaceFlex1 } from 'atoms/Space';
import {Body, Subtitle, Title} from 'atoms/Typography';
import { Channel, ChannelCategory } from 'api/channels';
import { Column, Row } from 'atoms/Layout';
import { useNavigate } from 'react-router-dom';
import {ArrowsPointingOut, CalendarDays, ChatBubbleLeftEllipsis} from 'atoms/Icon';
import { useAppContext } from 'context/AppContext';
import { toSentiment } from 'model/feedback';
import ClipboardIcon from '../molecules/ClipboardIcon';
import QRCodeIcon from '../molecules/QRCodeIcon';
import moment from "moment";

interface Props {
    channel: Channel;
    all?: boolean;
}

const SHOW_CATEGORY: boolean = false

const CHANNEL_CATEGORY_LABEL: Record<ChannelCategory, string> = {
    [ChannelCategory.PHYSICAL_LOCATION]: "Physical Location",
    [ChannelCategory.EVENT]: "Event",
    [ChannelCategory.COMMUNITY_OUTREACH]: "Community Outreach",
    [ChannelCategory.DIGITAL]: "Digital",
}

const ChannelCard: FC<Props> = ({ channel, all }) => {
    const { feedback } = useAppContext()
    const navigate = useNavigate()
    const relatedFeedback = feedback.value?.filter(f => all || f.channelId === channel.id) ?? []

    const averageSentimentScore = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0
    const sentiment = relatedFeedback.length ? toSentiment(averageSentimentScore) : undefined

    const goToFeedback = () => {
        if (all) {
            navigate(`/dashboard/insights`)
            return
        }
        navigate(`/dashboard/insights/${channel.id}`)
    }

    const dateCreated = useMemo(() => moment(channel.createdAt).format('MMMM Do YYYY'), [channel.createdAt])

    return <SentimentCard onClick={goToFeedback} sentiment={sentiment}>
        { !all && <Row gap="small" nowrap>
            <Title>{channel.name}</Title>
            <HorizontalSpaceFlex1 />
            <QRCodeIcon url={`/api/channel/${channel.id}/qr`} />
            <ClipboardIcon content={`https://beta.voop.ai/feedback/channel/${channel.id}`}>Copy Feedback Page URL</ClipboardIcon>
        </Row>}
        {
            all && <Row gap="small" nowrap><Title>{channel.name}</Title></Row>
        }
        <VerticalSpaceFlex1 />
        { channel.prompt && <Row vcenter gap="small" nowrap>
            <Subtitle>{channel.prompt}</Subtitle>
        </Row> }
        <VerticalSpaceFlex1 />
        { all && <Row>
            <ArrowsPointingOut width={45} height={45} />
        </Row>}
        { !all && SHOW_CATEGORY && <Row>
            <Body>
                {CHANNEL_CATEGORY_LABEL[channel.category]}
            </Body>
        </Row>}
        <Row>
            <Column size="full" gap="small">

                { !all && <Row vcenter gap="small">
                    <CalendarDays />
                    <Body>Created {dateCreated}</Body>
                </Row> }
                <Row vcenter gap="small">
                    <ChatBubbleLeftEllipsis />
                    <Body>{relatedFeedback.length} Voops</Body>
                </Row>
            </Column>
        </Row>

    </SentimentCard>
}

export default ChannelCard;
