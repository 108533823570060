import React, { FC } from 'react';
import { FeedbackTheme } from '../theme';
import styled from 'styled-components';
import { Hint } from './typography';

interface Props {
    stepCount: number;
    currentStep: number; // 0-indexed
    feedbackTheme: FeedbackTheme;
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

const BarBackground = styled.div`
    width: 100%;
    height: 4px;
    position: relative;
`;

const BarForeground = styled.div<{ feedbackTheme: FeedbackTheme, percent: number }>`
    width: ${({ percent }) => percent}%;
    height: 100%;
    background-color: ${({ feedbackTheme }) => feedbackTheme.colors.foreground};
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.5s ease-in-out;
`;

const ProgressBar: FC<Props> = ({ stepCount, currentStep, feedbackTheme }) => {
    if (stepCount <= 1) {
        return <></>
    }

    const percent = (currentStep + 1) / (stepCount + 1) * 100;

    return <Container>
        <BarBackground>
            <BarForeground feedbackTheme={feedbackTheme} percent={percent} />
        </BarBackground>
        <Hint feedbackTheme={feedbackTheme}>Step {currentStep + 1} of {stepCount}</Hint>
    </Container>
}

export default ProgressBar;
