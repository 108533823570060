import React, { FC } from 'react';
import { MainTitle } from '../../atoms/Typography';
import { Column, Row } from '../../atoms/Layout';
import { useAppContext } from '../../context/AppContext';
import ChannelCards from '../../templates/ChannelCards';
import WelcomeChecklist from '../../organisms/WelcomeChecklist';
import { VerticalSpace3 } from '../../atoms/Space';

const Home: FC = () => {
    const user = useAppContext().user.value!


    return <Column size="full" gap="large">
        <VerticalSpace3 />
        <Row>
            <Column>
                <MainTitle nowrap>{`Welcome ${user.name}`}</MainTitle>
            </Column>
        </Row>
        <VerticalSpace3 />
        <Row gap="large">
            <Column>
                <WelcomeChecklist />
            </Column>
            <Column size="full">
                <ChannelCards />
            </Column>
        </Row>
    </Column>
}

export default Home;
