
import React, { FC } from 'react';
import styled from 'styled-components';
import { QRCode } from '../atoms/Icon';
import Tooltip from '../atoms/Tooltip';

interface Props {
    url: string
}

const Container = styled.a`
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    color: black;
`;

const QRCodeIcon: FC<Props> = ({ url }) => {
    const [hovered, setHovered] = React.useState(false);
    const [recentlyDownloaded, setRecentlyDownloaded] = React.useState(false);

    const click = (e: React.MouseEvent) => {
        e.stopPropagation();
        setRecentlyDownloaded(true);
        setTimeout(() => setRecentlyDownloaded(false), 4000);
    }

    return <Container download="qr.png" href={url} onClick={click}>
        <QRCode width="24" height="24" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} />
        { (hovered || recentlyDownloaded) && <Tooltip>{ recentlyDownloaded ? 'Generating...' : 'Generate Feedback Page QR Code'}</Tooltip> }
    </Container>
}

export default QRCodeIcon;
