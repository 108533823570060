import React, { FC } from 'react';
import styled from 'styled-components';
import { InformationCircle } from '../atoms/Icon';
import Tooltip from '../atoms/Tooltip';

interface Props {
    children: React.ReactNode
}

const RelativeDiv = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
`;

const InformationTooltip: FC<Props> = ({ children }) => {
    const [toggled, setToggled] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const show = toggled || hovered;

    return <RelativeDiv>
        <InformationCircle onClick={() => setToggled(!toggled)} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} width="24" height="24" />
        { show && <Tooltip>{children}</Tooltip> }
    </RelativeDiv>
}

export default InformationTooltip;
