
import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { Link } from '../atoms/Icon';
import Tooltip from '../atoms/Tooltip';

interface Props {
    content: string
    children: React.ReactNode
}

const Container = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

const ClipboardIcon: FC<Props> = ({ content, children }) => {
    const [hovered, setHovered] = React.useState(false);
    const [recentlyCopied, setRecentlyCopied] = React.useState(false);
    const show = hovered || recentlyCopied;

    const copy = (e: MouseEvent) => {
        void navigator.clipboard.writeText(content)
        setRecentlyCopied(true)
        e.stopPropagation()
        // Set RecentlyCopied to false after 4 seconds
        setTimeout(() => setRecentlyCopied(false), 4000)
    }

    return <Container onClick={copy}>
        <Link width="24" height="24" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} />
        { show && <Tooltip>{recentlyCopied ? 'Copied' : children}</Tooltip> }
    </Container>


}

export default ClipboardIcon;
