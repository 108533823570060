import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
`

const sizes = {
    text: '1em',
    small: '32px',
    medium: '64px',
    large: '128px',
}

const Loading = styled.div<{ size?: 'text' | 'small' | 'medium' | 'large' }>`
    width: ${({ size }) => sizes[size || 'text']};
    height: ${({ size }) => sizes[size || 'text']};
    border-radius: 50%;
    background: conic-gradient(#4C46DC, #FD6779, #FBBF5D, #61CDA8, #4C46DC);
  
    animation: ${spin} 1s linear infinite;
`;

export default Loading;
