import React, { FC } from 'react';
import Moss from './Moss.jpg'
import styled from 'styled-components';

const CEOImageImg = styled.img`
    border-radius: 50%;
    width: 70px;
    height: 70px;
`;

const CEOImage: FC = () => {
    return <CEOImageImg src={Moss} alt="Mohsin - Our CEO" />
}

export default CEOImage;
