import React, { FC, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import SentimentCard from '../atoms/SentimentCard';
import { VerticalSpaceFlex1 } from '../atoms/Space';
import { Label, Title } from '../atoms/Typography';
import { TextInput } from '../atoms/Input';
import { Row } from '../atoms/Layout';
import Button from '../atoms/Button';
import { ChannelCategory, createChannel } from '../api/channels';

const AddChannelCard: FC = () => {
    const channels = useAppContext().channels
    const [newChannelName, setNewChannelName] = useState<string>("")
    const [question, setQuestion] = useState<string>("")
    const [questionEdited, setQuestionEdited] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)

    const duplicateName = channels.value?.find(channel => channel.name === newChannelName)

    const onSubmit = async () => {
        if (!channels.value) return;

        setSaving(true)
        const channel = await createChannel(newChannelName, ChannelCategory.COMMUNITY_OUTREACH, question);
        setSaving(false)
        setNewChannelName("")
        setQuestionEdited(false)
        setQuestion("")
        channels.setValue([...channels.value, channel])
    }

    return <SentimentCard>
        <Row>
            <Title>Create Question</Title>
        </Row>
        <VerticalSpaceFlex1 />
        <Label>Project Name</Label>
        <TextInput value={newChannelName} onChange={setNewChannelName} max={64} error={!!duplicateName}/>
        <Label>What would you like to ask your community?</Label>
        <TextInput value={question} onChange={value => {
            setQuestion(value)
            setQuestionEdited(true)
        }} max={256} error={questionEdited && !question}/>
        <Button onClick={onSubmit}
                disabled={!channels.value || newChannelName.length === 0 || !question || !!duplicateName}
                loading={saving}>Add</Button>
    </SentimentCard>
}

export default AddChannelCard;
