import styled from 'styled-components';

const Layout = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 28px 28px 28px;
    gap: 40px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    width: 100%;
`;

export default Layout;
