import React, { FC, useEffect } from 'react';
import { Column, HorizontalDivider, Row } from 'atoms/Layout';
import Panel from 'atoms/Panel';
import { getInsights, Insights } from 'api/insights';
import Loading from 'atoms/Loading';
import { useAppContext } from 'context/AppContext';
import { Sparkles } from 'atoms/Icon';
import typography, { Body, Label, LinkButton } from 'atoms/Typography';
import styled from 'styled-components';
import colors from 'atoms/colors';
import { Sentiment, SENTIMENT_COLORS, SENTIMENT_NAMES, toSentiment } from 'model/feedback';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { Components } from 'react-markdown/lib/ast-to-react';

interface Props {
    channelId?: string;
}

const RowCircledNumber = styled.div`
    ${typography.subtitle};
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${colors.background};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-4px) translateX(-4px);
`

const SentimentPill = styled.div<{ sentiment?: Sentiment }>`
  ${typography.label};
  display: inline-flex;
  padding: 1px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 15px;
  background: ${props => props.sentiment ? SENTIMENT_COLORS[props.sentiment] : colors.brand};
  cursor: pointer;
  font-weight: 700;
`;

const COMPONENTS: Components = {
    code: React.Fragment,
    pre: React.Fragment,
    p: React.Fragment,
    ol: React.Fragment,
    li: p => <p>{p.children}</p>
}

const InsightThemes: FC<Props> = ({ channelId }) => {
    const { feedback } = useAppContext();
    const [insights, setInsights] = React.useState<Insights | undefined>();

    useEffect(() => {
        void feedback.load()
    }, [])

    useEffect(() => {
       setInsights(undefined)
       getInsights(channelId).then(result => {
           setInsights(result)
           if (result.themes.length === 0 || !result.themes[0]?.content) {
               refreshInsights();
           }
       })
    }, [channelId])

    const refreshInsights = () => {
        setInsights(undefined)
        getInsights(channelId, true).then(setInsights)
    }

    if (!insights) {
        return <Column size="full" gap="large">
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
        </Column>
    }

    const insightDate = moment(insights.createdAt).fromNow()

    return <Column size="full" gap="large">
        <Panel>
            <Column gap="large">
                <div style={{ minWidth: 260}} />
                <Row gap="small" vcenter>
                    <Sparkles width={24} height={24} />
                    <Label>Last updated {insightDate}.  </Label>
                    <LinkButton onClick={refreshInsights}>Refresh</LinkButton>
                </Row>
                {insights.themes.length === 0 && <Row gap="small">
                    <Column>
                        <RowCircledNumber>1</RowCircledNumber>
                    </Column>
                    <Column gap="small" size="full">
                        <Body>
                            There was insufficient feedback from your customers to generate useful insights.  Try placing your feedback links in more prominent locations.
                        </Body>
                    </Column>
                </Row>}
                {insights.themes.map((theme, i) => {
                    const sentiment = toSentiment(theme.sentimentScore)

                    return <>
                        <Row key={2 * i} gap="small">
                            <Column>
                                <RowCircledNumber>{i + 1}</RowCircledNumber>
                            </Column>
                            <Column gap="small" size="full">
                                <Body>
                                    {theme.content}
                                </Body>
                                <Row gap="small">
                                    {theme.topic && <SentimentPill>{theme.topic?.name}</SentimentPill>}
                                    <SentimentPill sentiment={sentiment}>{SENTIMENT_NAMES[sentiment]}</SentimentPill>
                                </Row>
                            </Column>
                        </Row>
                        {i !== insights.themes.length - 1 && <HorizontalDivider key={2 * i + 1}/>}
                    </>;
                })}
            </Column>
        </Panel>
        <Panel>
            <Row gap="small" vcenter>
                <Sparkles width={24} height={24} />
                <Label>Action plan</Label>
            </Row>
            <Row>
                <Body>
                    <ReactMarkdown components={COMPONENTS}>{insights.actionPlan.replaceAll('`', '').trim()}</ReactMarkdown>
                </Body>
            </Row>
        </Panel>
    </Column>
}

export default InsightThemes;
