import React, { FC, useEffect } from 'react';
import SaturationLuminositySquare from '../atoms/SaturationLuminositySquare';
import HueSlider from '../atoms/HueSlider';
import { Column } from '../atoms/Layout';
import { TextInput } from '../atoms/Input';

interface Props {
    color: string; // Hex color
    onChange: (color: string) => void;
}

const hexToHsl = (hex: string): [number, number, number] => {
    let r = 0, g = 0, b = 0;

    // 3 digits
    if (hex.length === 4) {
        r = parseInt("0x" + hex[1] + hex[1], 16);
        g = parseInt("0x" + hex[2] + hex[2], 16);
        b = parseInt("0x" + hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt("0x" + hex[1] + hex[2], 16);
        g = parseInt("0x" + hex[3] + hex[4], 16);
        b = parseInt("0x" + hex[5] + hex[6], 16);
    }

    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    h = Math.round(360 * h);

    return [h, s, l];
};

const hue2rgb = (p: number, q: number, t: number) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
};

const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
};

const hslToHex = (h: number, s: number, l: number): string => {
    let r, g, b;

    h /= 360;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
};

const ColorPicker: FC<Props> = ({ color, onChange }) => {
    const [hue, setHue] = React.useState(0);
    const [saturation, setSaturation] = React.useState(0);
    const [luminosity, setLuminosity] = React.useState(0);
    const [inputValue, setInputValue] = React.useState(color);
    const newColor = React.useRef<string | null>(null);

    useEffect(() => {
        setInputValue(color)
        if (color === newColor.current) return;

        const [newHue, newSaturation, newLuminosity] = hexToHsl(color);
        setHue(newHue);
        setSaturation(newSaturation);
        setLuminosity(newLuminosity);
    }, [color]);

    useEffect(() => {
        if (inputValue !== color && inputValue.match(/^#[0-9a-f]{6}$/i)) {
            newColor.current = inputValue;
            onChange(inputValue);
        }
    }, [inputValue])

    const changeSatLum = (saturation: number, luminosity: number) => {
        setSaturation(saturation);
        setLuminosity(luminosity);
        const color = hslToHex(hue, saturation, luminosity);
        setInputValue(color);
        newColor.current = color;
        onChange(color);
    }

    const changeHue = (hue: number) => {
        setHue(hue);
        const color = hslToHex(hue, saturation, luminosity);
        setInputValue(color);
        newColor.current = color;
        onChange(color);
    }

    return <Column gap="medium">
        <SaturationLuminositySquare hue={hue} saturation={saturation} luminosity={luminosity} onChange={(saturation, luminosity) => changeSatLum(saturation, luminosity)}/>
        <HueSlider hue={hue} onChange={changeHue} />
        <TextInput value={inputValue} onChange={setInputValue} placeholder="Hex Color Code (#000000)"/>
    </Column>
}

export default ColorPicker;
