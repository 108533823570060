
import React, { FC } from 'react';

interface Props {
  color: string
}

const MicrophoneIcon: FC<Props> = ({ color }) => {
    return <svg width="70" height="70" viewBox="25 25 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.4769 51.7432C47.3697 51.7432 49.1851 50.9913 50.5235 49.6528C51.8619 48.3143 52.6139 46.499 52.6139 44.6061V35.0901C52.6139 32.5404 51.2536 30.1842 49.0454 28.9092C46.8371 27.6344 44.1166 27.6344 41.9084 28.9092C39.7001 30.1842 38.3398 32.5404 38.3398 35.0901V44.6061C38.3398 46.499 39.0917 48.3143 40.4302 49.6528C41.7687 50.9912 43.584 51.7432 45.4769 51.7432Z" fill={color}/>
        <path d="M54.9928 44.6055C54.9928 48.0051 53.179 51.1467 50.2347 52.8464C47.2905 54.5465 43.663 54.5465 40.7187 52.8464C37.7745 51.1465 35.9607 48.0049 35.9607 44.6055H33.5817C33.5668 47.5646 34.6554 50.4229 36.6351 52.6227C38.6145 54.8222 41.343 56.205 44.2874 56.5008V61.2589H46.6664V56.5008C49.6106 56.2051 52.3391 54.8223 54.3186 52.6227C56.2983 50.4232 57.3869 47.565 57.372 44.6055H54.9928Z" fill={color}/>
    </svg>
}

export default MicrophoneIcon;
