import React, { FC } from 'react';
import { StepProps } from './Step';
import { Column } from '../atoms/Layout';
import RecorderButton from '../molecules/RecorderButton';
import Playback from '../molecules/Playback';
import { Title } from '../atoms/typography';
import { FeedbackPageAnonymizeSetting } from '../../api/feedbackPage';
import TextFeedback from '../molecules/TextFeedback';

const FeedbackStep: FC<StepProps> = ({ feedbackTheme, onSubmit }) => {
    const [audioBlob, setAudioBlob] = React.useState<Blob | null>(null);
    const [anonymize, setAnonymize] = React.useState(false);
    const [useText, setUseText] = React.useState(false);

    const submit = () => {
        if (audioBlob) {
            onSubmit({
                blob: audioBlob,
                anonymize,
            });
        }
    }

    React.useEffect(() => {
        if (feedbackTheme.anonymize === FeedbackPageAnonymizeSetting.ALWAYS) {
            setAnonymize(true);
        }
        if (feedbackTheme.anonymize === FeedbackPageAnonymizeSetting.NEVER) {
            setAnonymize(false);
        }
    }, [feedbackTheme]);

    return <Column>
        <Title center feedbackTheme={feedbackTheme}>{feedbackTheme.prompt}</Title>
        { audioBlob && <Playback blob={audioBlob} feedbackTheme={feedbackTheme} onCancel={() => setAudioBlob(null)} anonymize={anonymize} onAnonymizeChange={setAnonymize} onAccept={submit}/> }
        { !audioBlob && !useText && <RecorderButton anonymize={anonymize} onRecordingComplete={setAudioBlob} feedbackTheme={feedbackTheme} onAnonymizeChange={setAnonymize} onSwitchToText={() => setUseText(true)} /> }
        { useText && <TextFeedback onSwitchToVoice={() => setUseText(false)} feedbackTheme={feedbackTheme} onSubmit={text => onSubmit({ text })} /> }
    </Column>
}

export default FeedbackStep;
