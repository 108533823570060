
import React, { FC } from 'react';
import Navigation from '../atoms/Navigation';
import Logo from '../atoms/Logo/index';
import { HorizontalSpaceFlex1 } from '../atoms/Space';
import NavigationLink from '../atoms/NavigationLink';
import AccountMenu from '../organisms/AccountMenu';

const DashboardHeader: FC = () => {
    return <Navigation>
        <Logo />
        <HorizontalSpaceFlex1 />
        <Navigation>
            <NavigationLink to="/dashboard/home">Home</NavigationLink>
            <NavigationLink to="/dashboard/insights">Insights</NavigationLink>
            <NavigationLink to="/dashboard/feedback">Voops</NavigationLink>
            <NavigationLink to="/dashboard/settings">Settings</NavigationLink>
        </Navigation>
        <HorizontalSpaceFlex1 />
        <AccountMenu />
    </Navigation>
}

export default DashboardHeader;
