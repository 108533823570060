import React, { FC } from 'react';
import { Column, HorizontalDivider, Row } from 'atoms/Layout';
import Panel from 'atoms/Panel';
import InsightScore from 'organisms/InsightScore';
import { useAppContext } from 'context/AppContext';
import Loading from 'atoms/Loading';
import { Label, Title } from 'atoms/Typography';
import {Sentiment, SENTIMENT_NAMES, toSentiment} from 'model/feedback';
import OverallSentimentBreakdown from 'organisms/OverallSentimentBreakdown';
import Accordion from '../molecules/Accordion';
import { SpaceType } from '../api/spaces';

interface Props {
    channelId?: string;
}

const VOOP_SCORE_BLURB: Record<SpaceType, string> = {
    GAMING: 'Voop Scores provide an at-a-glance assessment of a how a game is performing across the key areas of quality, fairness, community, and usability. Each score is calculated using an aggregate of all of your feedback, represented on a scale from 0 to 10.',
    MARKETING: 'Voop Scores provide an at-a-glance assessment of how your brand is received, across the key areas of awareness, equity, loyalty and reuptation. Each score is calculated using an aggregate of all of your feedback, represented on a scale from 0 to 10.',
}

interface VoopScore {
    name: string;
    topics?: string[];
    description?: string;
    tagline?: (sentiment: Sentiment) => string;
}

const GAMING_VOOP_SCORES: VoopScore[] = [
    {
        name: 'Quality score',
        topics: [
            'gameplay-mechanics',
            'graphics-and-design',
            'audio-and-sound',
            'performance-and-stability',
            'online-connectivity',
            'story-narrative-and-lore',
            'characterization',
            'historical-accuracy',
        ],
        description: 'A comprehensive evaluation of a game\'s core elements, ranging from gameplay mechanics to its narrative. It gauges artistic and technical excellence in graphics, sound, performance, and overall game design.'
    },
    {
        name: 'Fairness score',
        topics: [
            'microtransactions-and-monetization',
            'gameplay-balance',
            'player-progression-and-rewards',
            'content-and-updates',
            'loot-boxes',
        ],
        description: 'A metric that assesses how equitable a game feels to its players, considering aspects like monetization strategies and gameplay balance. It measures the game\'s commitment to providing a level playing field, valuing player progression, and offering fair rewards.'
    },
    {
        name: 'Usability score',
        topics: [
            'user-interface-and-usability',
            'game-difficulty-and-tutorials',
            'controls',
            'accessibility'
        ],
        description: 'A rating that examines the ease of interacting with the game, from its user interface to control schemes. It focuses on the game\'s accessibility, clarity of instructions, and the overall user experience, ensuring players of all levels can engage effectively.'
    },
    {
        name: 'Community score',
        topics: [
            'community-interactions',
            'moderation-and-reporting',
            'customer-support',
            'communication channels and tools',
            'feedback-suggestions-and-complaints',
        ],
        description: 'A measure of how well a game fosters a sense of community among its players. It evaluates the game\'s social features, communication tools, and customer support, ensuring players can connect with each other and the developers.'
    },
    {
        name: 'Overall score',
        tagline: (sentiment: Sentiment) => sentiment === Sentiment.POSITIVE ? 'Keep up the good work!' : ''
    }
]

const MARKETING_VOOP_SCORES: VoopScore[] = [
    {
        name: 'Product Score',
        topics: [
            'quality',
            'innovation',
            'usability',
            'design',
            'packaging',
        ],
        description: 'This refers to the physical product or service that the company is providing its users . '
    },
    {
        name: 'Price Score',
        topics: [
            'affordability',
            'value-for-money',
            'pricing-strategy',
            'perceived-value',
            'price-transparency',
        ],
        description: 'This refers to how much customers will pay to purchase products and services and if it is a fair price for what the experience they receive. '
    },
    {
        name: 'Place Score',
        topics: [
            'distribution-channels',
            'marketing-accessibility',
            'location',
            'inventory-management',
            'channel-partnerships',
        ],
        description: 'Place refers to where the customer can buy the products or services and how accessible they are to the customer. '
    },
    {
        name: 'Promotion Score',
        topics: [
            'advertising',
            'marketing-channels',
            'messaging',
            'branding',
            'customer-engagement',
        ],
        description: 'Promotion refers to how your product or service is being promoted, where will people see the product and is it perceived in a positive or negative way. '
    },
    {
        name: 'People Score',
        topics: [
            'customer-service',
            'employee-training',
            'employee-attitude',
            'customer-feedback-handling',
            'company-culture',
        ],
        description: "People refers to every person that is involved in the whole production line of your product. This is from the physical mechanics building it, the person promoting it on TV and the person selling it to you in the store."
    },
    {
        name: 'Overall score',
        tagline: (sentiment: Sentiment) => sentiment === Sentiment.POSITIVE ? 'Keep up the good work!' : ''
    }
]

const VOOP_SCORES: Record<SpaceType, VoopScore[]> = {
    GAMING: GAMING_VOOP_SCORES,
    MARKETING: MARKETING_VOOP_SCORES
}



const InsightScores: FC<Props> = ({ channelId }) => {
    const { feedback, space } = useAppContext();
    const relatedFeedback = feedback.value?.filter(f => !channelId || f.channelId === channelId) ?? []

    const filterFeedbackByTopic = (topics: string[]) => {
        // Return only feedback that has a topic that matches one of the topics in the array
        return relatedFeedback.filter(f => f.analysis.topics.some(t => topics.includes(t.id)))
    }
    const averageSentiment = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0;
    const sentiment = toSentiment(averageSentiment)
    const spaceType = space.value?.spaceType ?? SpaceType.GAMING

    if (feedback.loading) {
        return <Column gap="large" size="half">
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
        </Column>
    }

    return <Column gap="large" size="half">
        <Panel>
            <Column gap="medium">
                <Label>Overall Sentiment</Label>
                <Row>
                    <Title>{SENTIMENT_NAMES[sentiment]}</Title>
                </Row>
                <OverallSentimentBreakdown feedback={relatedFeedback} />
            </Column>
        </Panel>
        <Panel>
            <Column gap="large">
                <Accordion title="What's a Voop Score?">
                    {VOOP_SCORE_BLURB[spaceType]}
                </Accordion>
                { VOOP_SCORES[spaceType].map(score => {
                    const relatedFeedback = score.topics ? filterFeedbackByTopic(score.topics) : (feedback.value ?? [])
                    return <>
                        <HorizontalDivider />
                        <InsightScore key={score.name} title={score.name} feedback={relatedFeedback} description={score.description} tagline={score.tagline} />
                    </>
                })}
            </Column>
        </Panel>
    </Column>
}

export default InsightScores;
