import React, { FC } from 'react';
import imageSrc from './PhonePreview.png';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
}

const Container = styled.div`
  position: relative;
  width: 420px;
  height: 860px;
  background-image: url(${imageSrc});
  background-size: cover;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding-top: 22.5px;
  padding-bottom: 22.5px;
  transform: scale(0.8) translateY(-12%);
  margin-bottom: -40%;
`;

const InnerContainer = styled.div`
  width: 375px;
  border-radius: 45px;
  overflow: auto;
`;

const PhonePreview: FC<Props> = ({children}) => {
    return <Container>
        <InnerContainer>
            {children}

        </InnerContainer>
    </Container>
}

export default PhonePreview;
