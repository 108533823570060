import React, { FC } from 'react';
import { Column, Row } from '../atoms/Layout';
import { Label } from '../atoms/Typography';
import RadioButton from '../atoms/RadioButton';

export interface RadioOption {
    value: string
    label: string
}

interface Props {
    value?: string
    options: RadioOption[]
    onChange: (value: string) => void
}

const RadioGroup: FC<Props> = ({ value, options, onChange }) => {
    return <Column gap="small">
        {options.map((option) => {
           return <Row key={option.value} vcenter gap="small">
               <RadioButton value={value === option.value} onChange={() => onChange(option.value)} />
               <Label>{option.label}</Label>
           </Row>
        })}
    </Column>
}

export default RadioGroup;
