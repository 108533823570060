
import React, { FC, useEffect } from 'react';
import WelcomeWizard from '../templates/WelcomeWizard';
import { getUser } from '../api/users';
import { useNavigate } from 'react-router-dom';
import { getSpace, Space } from '../api/spaces';

const Welcome: FC = () => {
    const navigate = useNavigate()
    const [space, setSpace] = React.useState<Space | undefined>(undefined)

    useEffect(() => {
        getUser().then((user) => {
            if (user === null) {
                navigate('/login')
            } else {
                getSpace().then(setSpace)
            }
        })
    }, [])

    return <WelcomeWizard space={space} />
}

export default Welcome;
