import { useEffect, useRef, useState } from 'react';

export interface AudioPlayback {
    play: () => void;
    stop: () => void;
    isPlaying: boolean;
    duration: number;
    currentTime: number;
    completion: number; // 0 to 1
    waveform: (parts: number) => number[];
}

const useAudioPlayback = (audio: Blob): AudioPlayback => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const volumes = useRef<number[]>([]);
    const audioContext = useRef<AudioContext | null>(null);
    const audioBuffer = useRef<AudioBuffer | null>(null);
    const audioSource = useRef<AudioBufferSourceNode | null>(null);

    const onNewAudio = async (audio: Blob) => {
        audioContext.current = new AudioContext();
        audioBuffer.current = await audioContext.current.decodeAudioData(await audio.arrayBuffer());
        const channelData = audioBuffer.current.getChannelData(0);
        const binSize = 1024; // Define bin size as needed
        let index = 0;
        const newVolumes: number[] = [];

        while (index < channelData.length) {
            const slice = channelData.slice(index, index + binSize);
            const sum = slice.reduce((acc, val) => acc + val, 0);
            newVolumes.push(sum / slice.length);
            index += binSize;
        }

        volumes.current = newVolumes;
        setDuration(audioBuffer.current.duration);
    }

    useEffect(() => {
        void onNewAudio(audio);
    }, [audio]);

    // Register interval to update currentTime
    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying) {
                setCurrentTime(currentTime => currentTime + 0.1);
            }
        }, 100);
        return () => clearInterval(interval);
    }, [isPlaying]);

    const play = async () => {
        setIsPlaying(true);
        setCurrentTime(0);

        if (audioContext.current && audioBuffer.current) {
            const source = audioContext.current.createBufferSource();
            source.buffer = audioBuffer.current;
            source.connect(audioContext.current.destination);
            source.start(audioContext.current.currentTime);
            source.onended = () => setIsPlaying(false);
            audioSource.current = source;
        }
    }

    const stop = () => {
        setIsPlaying(false);
        setCurrentTime(0);

        if (audioSource.current) {
            audioSource.current.stop();
        }
    }

    const waveform = (parts: number) => {
        const partSize = Math.floor(volumes.current.length / parts);
        const waveform: number[] = [];

        for (let i = 0; i < parts; i++) {
            const slice = volumes.current.slice(i * partSize, (i + 1) * partSize);
            const sum = slice.reduce((acc, val) => acc + val, 0);
            waveform.push(sum / slice.length);
        }

        // Normalize waveform so that the highest value is 1 and the lowest is 0
        const max = Math.max(...waveform);
        const min = Math.min(...waveform);

        for (let i = 0; i < waveform.length; i++) {
            waveform[i] = (waveform[i] - min) / (max - min);
        }

        return waveform;
    }

    const completion = currentTime / duration;

    return {
        play,
        stop,
        isPlaying,
        duration,
        currentTime,
        completion,
        waveform,
    };
}

export default useAudioPlayback;
