import React, { FC, useEffect } from 'react';
import { Column, Row } from '../atoms/Layout';
import { Label, Title} from '../atoms/Typography';
import { VerticalSpace2 } from '../atoms/Space';
import { TextAreaInput, TextInput } from '../atoms/Input';
import Button from '../atoms/Button';
import Wizard from '../organisms/Wizard';
import BackButton from '../molecules/BackButton';
import { Space, SpaceType, updateSpace, UpdateSpaceCommand } from '../api/spaces';
import { useNavigate } from 'react-router-dom';
import RadioGroup from '../molecules/RadioGroup';
import { COMMUNITY_SIZES } from '../pages/Dashboard/Settings/Space';

interface Props {
    space?: Space
}

const WelcomeWizard: FC<Props> = ({ space }) => {
    const navigate = useNavigate()

    const [step, setStep] = React.useState<number>(1)
    const [saving, setSaving] = React.useState<boolean>(false)

    const [update, setUpdate] = React.useState<UpdateSpaceCommand>({
        name: '',
        welcomeEmailSent: false,
        spaceType: SpaceType.GAMING,
        contextValues: {},
    })
    const setField = (field: keyof UpdateSpaceCommand) => (value: any) => setUpdate({ ...update, [field]: value })
    const setContextValue = (field: string) => (value: any) => setUpdate({ ...update, contextValues: { ...update.contextValues, [field]: value } })

    useEffect(() => {
        if (space) {
            setUpdate({
                name: space?.name ?? '',
                welcomeEmailSent: false,
                spaceType: space.spaceType,
            })
        }
    }, [space])

    const onSubmit = async () => {
        if (!space) {
            return
        }

        setSaving(true)

        try {
            await updateSpace(update)
            navigate('/dashboard/home')
        } catch (e) {
            setSaving(false)
        }
    }

    return <Wizard currentStep={step} stepCount={2}>
        {step == 1 && <Column gap="small">
            <Title>Tell us about you and your company so we can customize your experience</Title>
            <VerticalSpace2 />
            <Label>Company Name</Label>
            <TextInput value={update.companyName || ""} onChange={setField("companyName")} max={64} half />
            <VerticalSpace2 />
            <Label>Website URL</Label>
            <TextInput value={update.contextValues?.companyWebsite || ""} onChange={setContextValue("companyWebsite")} max={64} half />
            <VerticalSpace2 />
            <Label>Provide a brief overview of your company's mission and vision</Label>
            <TextAreaInput max={4096} value={update.companyMission || ""} onChange={setField("companyMission")} placeholder="Our mission is to..." />
            <VerticalSpace2 />
            <Row>
                <Button disabled={!update.companyName?.trim()} onClick={() => setStep(2)}>Continue</Button>
            </Row>
        </Column>}
        {step == 2 && <Column gap="small">
            <BackButton onClick={() => setStep(1)} />
            <VerticalSpace2 />
            {
                space?.spaceType === SpaceType.GAMING && <>
                    <Label>Please describe your products / games to allow the AI to provide more targeted insights:</Label>
                    <TextAreaInput max={4096} value={update.gameDescription || ""} onChange={setField("gameDescription")} placeholder="Fortnite is a multiplayer battle royale shooter.  Its primary gameplay features are…" />
                    <VerticalSpace2 />
                    <Label>Please describe your community to allow the AI to provide more targeted insights:</Label>
                    <TextAreaInput max={4096} value={update.communityDescription || ""} onChange={setField("communityDescription")} />
                    <VerticalSpace2 />
                    <Label>Who is your primary target audience or customer base?</Label>
                    <TextAreaInput max={4096} value={update.targetAudience || ""} onChange={setField("targetAudience")} placeholder="Primary, Secondary, Tertiary, Age, Gender & Demographics" />
                    <VerticalSpace2 />
                    <Label>How large is your community?</Label>
                    <RadioGroup value={update.communitySize} options={COMMUNITY_SIZES} onChange={setField("communitySize")} />
                    <VerticalSpace2 />
                </>
            }
            {
                space?.spaceType === SpaceType.MARKETING && <>
                    <Label>Please describe your value proposition / what makes you different to allow the AI to provide more targeted insights:</Label>
                    <TextAreaInput max={4096} value={update.contextValues?.valueProposition || ""} onChange={setContextValue("valueProposition")}  />
                    <VerticalSpace2 />
                    <Label>What are the primary products or services you offer?</Label>
                    <TextAreaInput max={4096} value={update.contextValues?.productsAndServices || ""} onChange={setContextValue("productsAndServices")} />
                    <VerticalSpace2 />
                    <Label>What product(s) will be the focus of your usage of the Voop platform?</Label>
                    <TextAreaInput max={4096} value={update.contextValues?.primaryInterest || ""} onChange={setContextValue("primaryInterest")} />
                    <VerticalSpace2 />
                    <Label>Who is your primary target audience or customer base?</Label>
                    <TextAreaInput max={4096} value={update.targetAudience || ""} onChange={setField("targetAudience")} placeholder="Primary, Secondary, Tertiary, Age, Gender & Demographics" />
                    <VerticalSpace2 />
                    <Label>How large is your customer base?</Label>
                    <RadioGroup value={update.communitySize} options={COMMUNITY_SIZES} onChange={setField("communitySize")} />
                    <VerticalSpace2 />
                    <Label>What product metrics are most important to you?</Label>
                    <TextAreaInput max={4096} value={update.contextValues?.productMetrics || ""} onChange={setContextValue("productMetrics")} />
                    <VerticalSpace2 />
                </>
            }
            <Label>What are your short-term objectives for this feedback and research?</Label>
            <TextAreaInput max={4096} value={update.goals || ""} onChange={setField("goals")} />
            <VerticalSpace2 />
            <Label>What are your long-term objectives?</Label>
            <TextAreaInput max={4096} value={update.goalsLongTerm || ""} onChange={setField("goalsLongTerm")} />
            <VerticalSpace2 />
            <Row>
                <Button onClick={onSubmit} loading={saving}>Continue</Button>
            </Row>
        </Column>}
    </Wizard>
}

export default WelcomeWizard;
