
import React, { FC, useEffect } from 'react';
import { FeedbackTheme } from '../theme';
import { Column } from '../atoms/Layout';
import { BodySmall, InlineLink, Subtitle } from '../atoms/typography';
import ButtonWithHalo from '../atoms/ButtonWithHalo';
import PlaybackVisualizer from '../atoms/PlaybackVisualizer';
import useAudioPlayback from '../hooks/useAudioPlayback';
import { anonymizeAudio } from '../../api/feedback';
import { FeedbackPageAnonymizeSetting } from '../../api/feedbackPage';
import Checkbox from '../atoms/Checkbox';

interface Props {
    blob: Blob
    feedbackTheme: FeedbackTheme
    onAccept: () => void
    onCancel: () => void
    anonymize: boolean
    onAnonymizeChange: (anonymize: boolean) => void
}

const AudioPlayer: FC<{ blob: Blob, feedbackTheme: FeedbackTheme }> = ({ blob, feedbackTheme }) => {
    const audioPlayback = useAudioPlayback(blob);
    const togglePlayback = () => {
        if (audioPlayback.isPlaying) {
            audioPlayback.stop();
        } else {
            audioPlayback.play();
        }
    }

    // When the component unmounts, stop the audio playback
    useEffect(() => {
        return () => {
            if (audioPlayback.isPlaying) {
                audioPlayback.stop();
            }
        }
    }, []);

    return <PlaybackVisualizer feedbackTheme={feedbackTheme} active={audioPlayback.isPlaying} time={audioPlayback.isPlaying ? audioPlayback.currentTime : audioPlayback.duration} completion={audioPlayback.completion} waveform={audioPlayback.waveform(15)} onClick={togglePlayback}/>
}

const Playback: FC<Props> = ({ blob, feedbackTheme, onCancel, onAccept, anonymize, onAnonymizeChange }) => {
    const [anonymizedAudio, setAnonymizedAudio] = React.useState<Blob | null>(null);

    useEffect(() => {
        setAnonymizedAudio(null);
        anonymizeAudio(blob).then(setAnonymizedAudio);
    }, [blob])

    return <Column>
        <Subtitle feedbackTheme={feedbackTheme}>Send or <InlineLink feedbackTheme={feedbackTheme} onClick={onCancel}>re-record message</InlineLink></Subtitle>
        <ButtonWithHalo haloSize={0} feedbackTheme={feedbackTheme} active={false} onClick={onAccept}>
            Send
        </ButtonWithHalo>

        { feedbackTheme.anonymize === FeedbackPageAnonymizeSetting.OPTIONAL && <Checkbox value={anonymize} label="Anonymize my Voice" onChange={onAnonymizeChange} feedbackTheme={feedbackTheme} /> }

        { anonymize && <>
            {!anonymizedAudio && <BodySmall feedbackTheme={feedbackTheme}>Anonymizing your recording...</BodySmall>}
            {anonymizedAudio && <>
                <BodySmall center feedbackTheme={feedbackTheme}>Your voice has been modified to keep your feedback anonymous</BodySmall>
                <AudioPlayer blob={anonymizedAudio} feedbackTheme={feedbackTheme} />
            </>}
        </> }


        { !anonymize && <>
            <BodySmall feedbackTheme={feedbackTheme}>Listen to your recording</BodySmall>
            <AudioPlayer blob={blob} feedbackTheme={feedbackTheme} />
        </> }
    </Column>

}

export default Playback;
