
import React, { FC, ReactNode } from 'react';
import { Column, Row } from '../atoms/Layout';
import ChecklistCheckmark from '../atoms/ChecklistCheckmark';

interface Props {
    children: ReactNode
    checked: boolean
    first: boolean
    last: boolean
}

const ChecklistItem: FC<Props> = ({ children, checked, first, last }) => {
    return  <Row nowrap>
        <ChecklistCheckmark checked={checked} first={first} last={last} />
        <Column padding="medium" size="full">
            {children}
        </Column>
    </Row>
}

export default ChecklistItem;
