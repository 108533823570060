import { FeedbackTheme, ThemeProps } from '../theme';
import styled from 'styled-components';
import { BodySmall } from './typography';
import React, { FC } from 'react';

interface Props {
    feedbackTheme: FeedbackTheme;
}

const Placeholder = styled.div<ThemeProps>`
    padding: 12px 16px;
    border: 1px solid ${({ feedbackTheme }) => feedbackTheme.colors.foreground};
    border-radius: 1000px;
`;

const Image = styled.img`
    width: 140px;
    height: 70px;
    object-fit: scale-down;
`;

const Logo: FC<Props> = ({ feedbackTheme }) => {
    if (feedbackTheme.logoUrl) {
        return <Image src={feedbackTheme.logoUrl} alt="Logo" />
    }

    if (feedbackTheme.showPlaceholders) {
        return <Placeholder feedbackTheme={feedbackTheme}>
            <BodySmall feedbackTheme={feedbackTheme}>Your Logo</BodySmall>
        </Placeholder>
    }

    return <></>
}

export default Logo;
