import {Emotion, FeedbackTopic, Gender} from "./feedback";
import axios from "axios";
import {Insights} from "./insights";
import moment from "moment";


export interface PlaygroundPromptKey {
    key: string // Text string to be replaced
    name: string
    description: string
}

export interface PlaygroundPromptData {
    feedbackKeys: PlaygroundPromptKey[]
    themeKeys: PlaygroundPromptKey[]
    actionPlanKeys: PlaygroundPromptKey[]
    topics: FeedbackTopic[]
}

export interface PlaygroundPrompt {
    feedbackItemTemplate: string;
    themeTemplate: string;
    actionPlanTemplate: string;
}

export interface PlaygroundFeedbackResponse {
    type: 'TEXT' | 'VOICE';
    textContent: string;
    sentimentScore: number;
    age?: number;
    emotion?: Emotion;
    gender?: Gender;
    topics: FeedbackTopic[];
    language: string;
}

export interface PlaygroundContext {
    companyName?: string;
    websiteSummary?: string;
    spaceName?: string;
    companyMission?: string;
    goalsShortTerm?: string;
    goalsLongTerm?: string;
    gameDescription?: string;
    communityDescription?: string;
    productsAndServices?: string;
    targetAudience?: string;
    communitySize?: string;
}

export interface PlaygroundRequest {
    context: PlaygroundContext;
    feedbackResponses: PlaygroundFeedbackResponse[];
    prompt: PlaygroundPrompt;
}

export type InsightStreamingSource = 'theme' | 'actionPlan'

export type InsightStreamingEvent = InsightStreamingStart | InsightStreamingStep | InsightStreamingEnd

export interface InsightStreamingStart {
    type: 'start',
    feedbackCount: number;
    themes: {
        topic?: FeedbackTopic;
        sentimentScore: number;
        index: number;
    }[]
}

export interface InsightStreamingStep {
    type: 'step',
    source: InsightStreamingSource;
    index: number;
    content: string;
}

export interface InsightStreamingEnd {
    type: 'end',
}

export const getPromptData = async (): Promise<PlaygroundPromptData> => {
    return (await axios.get('/api/promptPlayground')).data
}

export interface InsightStream {
    stopStream: () => void
}

export const generateInsights = async (request: PlaygroundRequest, onChunk: (results: Insights) => void): Promise<InsightStream> => {
    const postResponse = await axios.post('/api/promptPlayground', request)
    const jobId = postResponse.data
    const eventSource = new EventSource(`/api/promptPlayground/${jobId}`)

    let insights: Insights = {
        themes: [],
        actionPlan: '',
        feedbackCount: 0,
        createdAt: moment().toISOString()
    }

    const onStart = (start: InsightStreamingStart) => {
        insights.feedbackCount = start.feedbackCount
        insights.actionPlan = ''
        insights.themes = start.themes.map(t => ({
            content: '',
            topic: t.topic,
            sentimentScore: t.sentimentScore
        }))
        onChunk({...insights})
    }

    const onEvent = (event: InsightStreamingStep) => {
        if (event.source === 'theme') {
            insights.themes[event.index].content += event.content
        } else if (event.source === 'actionPlan') {
            insights.actionPlan += event.content
        }
        onChunk({...insights})
    }

    eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data)
        // If it has a feedbackCount, it's the start event
        if (data.type === 'start') {
            onStart(data)
        } else if (data.type === 'end') {
            eventSource.close()
        } else {
            onEvent(data)
        }
    }

    return {
        stopStream: () => {
            eventSource.close()
        }
    }
}