import React, { FC } from 'react';
import Preview from './Preview.png'
import styled from 'styled-components';

const MonitorImageImg = styled.img`
  margin-left: -150px;
  margin-right: -150px;
  width: calc(100% + 300px);
  margin-top: 50px;

  @media (max-width: 768px) {
    width: 100svw;
  }
`;

const MonitorImage: FC = () => {
    return <MonitorImageImg src={Preview} alt="Voop.ai" />
}

export default MonitorImage;
