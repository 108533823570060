import axios from 'axios';

export enum SpaceType {
    GAMING = 'GAMING',
    MARKETING = 'MARKETING',
}

export interface Space {
    id: string
    name: string
    spaceType: SpaceType
    companyName?: string
    gameTitle?: string
    welcomeEmailSent: boolean,
    companyMission?: string
    goals?: string
    goalsLongTerm?: string
    communityDescription?: string
    companySummary?: string
    gameDescription?: string
    productsAndServices?: string
    targetAudience?: string
    communitySize?: CommunitySize
    contextValues?: Record<string, string>
}

export enum CommunitySize {
    RANGE_0_TO_1K = 'RANGE_0_TO_1K',
    RANGE_1K_TO_10K = 'RANGE_1K_TO_10K',
    RANGE_10K_TO_100K = 'RANGE_10K_TO_100K',
    RANGE_100K_TO_500K = 'RANGE_100K_TO_500K',
    RANGE_500K_TO_1M = 'RANGE_500K_TO_1M',
    RANGE_1M_AND_UP = 'RANGE_1M_AND_UP'
}


export interface UpdateSpaceCommand {
    name: string
    spaceType: SpaceType
    companyName?: string
    gameTitle?: string
    welcomeEmailSent: boolean,
    companyMission?: string
    goals?: string
    companySummary?: string
    gameDescription?: string
    communityDescription?: string
    productsAndServices?: string
    targetAudience?: string
    communitySize?: CommunitySize
    contextValues?: Record<string, string>
    goalsLongTerm?: string
}

export const getSpace = async (): Promise<Space> => {
    return (await axios.get(`/api/space`)).data
}

export const updateSpace = async (space: UpdateSpaceCommand): Promise<Space> => {
    return (await axios.put(`/api/space`, space)).data
}
