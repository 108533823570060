
import React, { FC, useEffect } from 'react';
import { getUser } from '../api/users';
import { useNavigate } from 'react-router-dom';
import PlaceTileOrderWizard from '../templates/PlaceTileOrderWizard';

const PlaceTileOrder: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        getUser().then((user) => {
            if (user === null) {
                navigate('/login')
            }
        })
    }, [])

    return <PlaceTileOrderWizard />
}

export default PlaceTileOrder;
