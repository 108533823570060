import React, { FC, useEffect } from 'react';
import DashboardHeader from '../../templates/DashboardHeader';
import { Column, Row } from 'atoms/Layout';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Home';
import { AppContext, useNewAppContext } from 'context/AppContext';
import Loading from '../../atoms/Loading';
import Settings from './Settings/Settings';
import ViewFeedback from './ViewFeedback';
import Insights from 'pages/Dashboard/Insights';

const RedirectToHome: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/dashboard/home')
    }, [])

    return <div>Redirecting...</div>
}

const Dashboard: FC = () => {
    const navigate = useNavigate()
    const context = useNewAppContext();

    useEffect(() => {
        void context.user.load();
    }, [])

    useEffect(() => {
        if (context.user.loaded) {
            if (!context.user.value) {
                navigate('/login')
                return
            }

            void context.space.load();
        }
    }, [context.user.value, context.user.loading])

    useEffect(() => {
        if (context.space.loaded) {
            const space = context.space.value
            if (!space || !space.companyName) {
                navigate('/welcome')
                return
            }
        }
    }, [context.space.value, context.space.loading])

    return <AppContext.Provider value={context}>
        <Column size="full" padding="large" gap="large">
            <DashboardHeader />
            { context.user.value ? <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/insights" element={<Insights />} />
                <Route path="/insights/:channelId" element={<Insights />} />
                <Route path="/feedback/*" element={<ViewFeedback />} />
                <Route path="/settings/*" element={<Settings />} />
                <Route path="*" element={<RedirectToHome/>} />
            </Routes> : <Row size="full" center><Loading size="large" /></Row> }
        </Column>
    </AppContext.Provider>

}

export default Dashboard;
