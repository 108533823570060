import React, { FC } from 'react';
import { FeedbackResponse } from 'api/feedback';
import { Column, Row } from 'atoms/Layout';
import { Body, Label } from 'atoms/Typography';
import SentimentGauge from 'atoms/SentimentGauge';
import { Sentiment, toSentiment } from 'model/feedback';
import InformationTooltip from '../molecules/InformationTooltip';

interface Props {
    feedback: FeedbackResponse[];
    title: string;
    description?: string | React.ReactNode;
    tagline?: (sentiment: Sentiment) => string;
}

const SENTIMENT_DESCRIPTIONS: Record<Sentiment, string> = {
    [Sentiment.NEGATIVE]: 'Your score is poor. There is a high chance your community will seek alternatives if you don’t address this.',
    [Sentiment.NEUTRAL]: 'Your score is ok. You may want to look at upgrading parts of your service.',
    [Sentiment.POSITIVE]: 'Your score is good.  '
}

const InsightScore: FC<Props> = ({ feedback, title, description, tagline }) => {
    const averageSentiment = feedback.length ? feedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / feedback.length : 0;

    const sentiment = toSentiment(averageSentiment)
    const sentimentDescription = SENTIMENT_DESCRIPTIONS[sentiment] + (tagline ? ` ${tagline(sentiment)}` : '')


    return <Column>
        <div style={{ minWidth: 260}} />
        <Row size="full" nowrap gap="medium">
            <Column size="full">
                <Row vcenter gap="small">
                    <Body>{title}</Body>
                    { description && <InformationTooltip>{ description }</InformationTooltip> }
                </Row>
                <Label>{sentimentDescription}</Label>
            </Column>
            <Column>
                <SentimentGauge sentimentScore={averageSentiment} />
            </Column>
        </Row>
    </Column>

}

export default InsightScore;
