
import React, { FC } from 'react';
import { Row } from '../atoms/Layout';
import { BodySmall, InlineLink, Label } from '../atoms/typography';
import { FeedbackTheme } from '../theme';
import { HorizontalSpaceFlex1 } from '../../atoms/Space';
import TextAreaInput from '../atoms/TextAreaInput';
import styled from 'styled-components';
import Button from '../atoms/Button';
import SmallMicrophoneIcon from '../atoms/SmallMicrophoneIcon';

interface Props {
    feedbackTheme: FeedbackTheme;
    onSubmit: (text: string) => void;
    onSwitchToVoice: () => void;
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ActionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;


const TextFeedback: FC<Props> = ({ onSubmit, onSwitchToVoice, feedbackTheme }) => {
    const [text, setText] = React.useState<string>("");

    return <Container>
        <Row>
            <Label feedbackTheme={feedbackTheme}>Type in box below</Label>
            <HorizontalSpaceFlex1 />
        </Row>
        <TextAreaInput feedbackTheme={feedbackTheme} onChange={e => setText(e.target.value)}/>
        <ActionRow>
            <SmallMicrophoneIcon feedbackTheme={feedbackTheme} />
            <BodySmall feedbackTheme={feedbackTheme}>
                <InlineLink feedbackTheme={feedbackTheme} onClick={onSwitchToVoice}>
                    Use voice instead
                </InlineLink>
            </BodySmall>
            <HorizontalSpaceFlex1 />
            <Button disabled={!text} feedbackTheme={feedbackTheme} onClick={() => onSubmit(text)}>Send</Button>
        </ActionRow>
    </Container>
}

export default TextFeedback;
