
import React from 'react';
import { ThemeProps } from '../theme';
import styled from 'styled-components';

const Button = styled.button<ThemeProps>`
    background-color: ${props => props.feedbackTheme.colors.primary};
    color: white;
    padding: 15px 33px;

    font-size: 18px;
    font-family: DM Sans;
    font-weight: 700;
    word-wrap: break-word;
    border-radius: 70px;
    border: none;
    cursor: pointer;
  
    &:hover {
        background-color: ${props => props.feedbackTheme.colors.primaryHover};
    }
  
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
  
    transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
`;

export default Button;
