import React, { FC } from 'react';
import { ChevronLeft } from '../atoms/Icon';
import { Body } from '../atoms/Typography';
import styled from 'styled-components';

interface Props {
    onClick: () => void
}

const BackButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 8px;
`;

const BackButton: FC<Props> = ({ onClick }) => {
    return <BackButtonDiv onClick={onClick}>
        <ChevronLeft />
        <Body>Back</Body>
    </BackButtonDiv>
}

export default BackButton;
