
import React, { FC, useState } from 'react';
import { FormColumn, Row } from '../../../atoms/Layout';
import { Hint, Label } from '../../../atoms/Typography';
import { PasswordInput } from '../../../atoms/Input';
import { VerticalSpace2 } from '../../../atoms/Space';
import Button from '../../../atoms/Button';
import { changePassword } from '../../../api/users';

const Password: FC = () => {
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [wrongPassword, setWrongPassword] = useState(false)

    const onSubmit = async () => {
        setSaving(true)

        const success = await changePassword(password, newPassword)

        setSaving(false)
        setSaved(success)
        setWrongPassword(!success)
        setPassword('')
        setNewPassword('')
        setConfirmNewPassword('')
    }

    return <FormColumn>
        <Label>Current Password</Label>
        { wrongPassword && <Label red>Wrong Password</Label>}
        <PasswordInput value={password} onChange={setPassword} max={64} error={wrongPassword}/>
        <VerticalSpace2 />
        <Label>New Password</Label>
        <PasswordInput value={newPassword} onChange={setNewPassword} max={64} error={!!newPassword && newPassword.length < 8}/>
        <VerticalSpace2 />
        <Label>Confirm New Password</Label>
        <PasswordInput value={confirmNewPassword} onChange={setConfirmNewPassword} max={64} error={!!confirmNewPassword && (confirmNewPassword !== newPassword)}/>
        <VerticalSpace2 />
        { saved && <Hint>Password updated!</Hint> }
        <Row>
            <Button disabled={!password || newPassword.length < 8 || confirmNewPassword != newPassword} onClick={onSubmit} loading={saving}>Save</Button>
        </Row>
    </FormColumn>
}

export default Password;
